export const TWO_WEEKS_DURATION_ID = 9;

export const NOTIFICATION_CAUSES = {
  PATIENT_JOINED_VIDEO_CALL: "patient_joined_video_call",
};


export const SMILE = "Smile";
export const FRONT_VIEW = "FrontView";
export const RIGHT_SIDE = "RightSide";
export const LEFT_SIDE = "LeftSide";
export const TOP_TEETH = "TopTeeth";
export const BOTTOM_TEETH = "BottomTeeth";
export const CURRENT_ALIGNER_FRONT_SIDE = "CurrentAlignerFrontSide";
export const CURRENT_ALIGNER_LEFT_SIDE = "CurrentAlignerLeftSide";
export const CURRENT_ALIGNER_RIGHT_SIDE = "CurrentAlignerRightSide";
export const NEW_ALIGNER_FRONT_SIDE = "NewAlignerFrontSide";
export const NEW_ALIGNER_LEFT_SIDE = "NewAlignerLeftSide";
export const NEW_ALIGNER_RIGHT_SIDE = "NewAlignerRightSide";
export const FACE_NO_EXPRESSION = "FaceNoExpression";
export const SIDE = "Side";

export const PROGRESS_IMAGES_ORDER = [ FACE_NO_EXPRESSION, SMILE, SIDE , FRONT_VIEW, RIGHT_SIDE, LEFT_SIDE, TOP_TEETH, BOTTOM_TEETH,];
export const PROGRESS_IMAGES_ALIGNER_ORDER = [FRONT_VIEW,CURRENT_ALIGNER_FRONT_SIDE, RIGHT_SIDE, CURRENT_ALIGNER_RIGHT_SIDE, LEFT_SIDE, CURRENT_ALIGNER_LEFT_SIDE,NEW_ALIGNER_RIGHT_SIDE, NEW_ALIGNER_LEFT_SIDE ];

export const ALIGNERS_IN_PRODUCTION_STATUS = "Aligners in Production"
export const ALIGNERS_SHIPPED_STATUS = "Aligners Shipped"


export const SCAN_REQUIRED_MESSAGE = "Are you sure you want to submit prescription without scan files?"

export const CLINIC_PRODUCT_DELETE_MESSAGE = (type) => <span>You cannot delete the last remaining product. At least one {type} type product must be defined in the clinic portal to proceed. <br /><br />

  Please add a new product or modify the existing ones before attempting to delete this product.</span>;



export const ALIGNER_COLOR_CONFIGURATION = [
  "#85CDCA", // Light teal
  "#FF6F61", // Bright warm red
  "#FFC75F", // Warm golden yellow
  "#6B5B95", // Muted purple
  "#64B5F6", // Sky blue
  "#88B04B", // Soft green
  "#FF847C", // Coral pink
  "#45B8AC", // Cool teal
  "#F49AC2", // Light pink
  "#F4A460", // Sandy brown
  "#FF7043", // Vivid orange
  "#7FCDCD", // Cyan
  "#FF4081", // Bright pink
  "#92A8D1", // Cool blue-gray
  "#6A0572", // Deep purple
  "#9575CD", // Lavender
  "#FFD54F", // Warm yellow
  "#41B3A3", // Sea green
  "#daa520", // Goldenrod
  "#E15D44", // Reddish-orange
  "#F7CAC9", // Pale pink
  "#BC243C", // Dark magenta
  "#F9F871", // Lemon yellow
  "#C3447A", // Rose pink
  "#A7226E", // Deep magenta
  "#FF9671", // Peach
  "#E8A87C", // Soft tan
  "#9B2335", // Crimson
  "#00FFF0", // Soft aqua
  "#FFB347", // Tangerine
  "#A8E6CF", // Mint
  "#FFCA28", // Bright yellow
  "#FF8C94", // Soft red
  "#39A275", // Forest green
  "#C38D9E", // Light mauve
  "#6C5B7B", // Dusty purple
  "#A9A9A9", // Dark gray
  "#DB7093", // Pale violet red
  "#D4A5A5", // Light pink-beige
  "#B6B5A3", // Taupe
  "#FFAB91", // Peach-orange
  "#DC4C46", // Bright red
  "#C1C8E4", // Light periwinkle
  "#9191E9", // Soft blue
  "#FFAE42", // Bright yellow-orange
  "#D5A6BD", // Soft mauve
  "#D6C5C5", // Pale rose
  "#A0E7E5", // Light cyan
  "#FFF59D", // Pale yellow
  "#77DD77", // Light green
  "#2A363B", // Dark gray-blue
  "#F6D55C", // Warm yellow
  "#C06C84", // Soft rose
  "#355C7D", // Muted navy
  "#E27D60", // Coral
  "#99B898", // Sage green
  "#FFF176", // Bright lemon yellow
  "#B68973", // Soft brown
  "#F39237", // Orange
  "#636363", // Charcoal gray
  "#7986CB", // Dusty lavender
  "#B2DFDB", // Pale turquoise
  "#DCE775", // Light lime
  "#FDFD96", // Soft yellow
  "#F1F8E9", // Pale green
  "#FFF8E1", // Cream
  "#FFE082", // Soft gold
  "#FFEB3B", // Bright yellow
  "#FFCDD2", // Pale red
  "#D7CCC8", // Soft brown
  "#D65076", // Soft red-violet
  "#FFECB3", // Pale gold
  "#D9BF77", // Tan
  "#C2E0C6", // Soft green
  "#F7DB4F", // Golden yellow
  "#F5F5DC", // Pale beige
  "#FDB0C0", // Soft pink
  "#E2B227", // Gold
  "#FEBE7E", // Soft orange
  "#ED553B", // Bright coral
  "#B2EBF2", // Light cyan
  "#C8E6C9", // Light mint
  "#FFE0B2", // Soft peach
  "#EE4B2B", // Bright red
  "#6488EA", // Soft blue
  "#FFDF00", // Golden yellow
  "#F3E5F5", // Pale lavender
  "#E8EAF6", // Pale blue
  "#EFC050", // Gold
  "#E1F5FE", // Light blue
  "#DCEDC8", // Pale green
  "#E3F2FD", // Soft blue
  "#FBE9E7", // Pale coral
  "#C6FFDD", // Soft green
  "#FFB6C1",
  "#4682B4",
  "#32CD32",
  "#DAA560",
  "#8A2BE2",
  "#FF4500",
];

export const APPROVE_AND_ORDER_PLAN = "Approve Plan"
export const ORDER_REFINEMENT_PLAN = "Approve Plan"

export const LAB_CHAT = "lab-chat"
export const PATIENT_CHAT = "patient-chat"
export const GROUP_CHAT = "group-chat"

export const chatOptions = [
  {
    label: "Lab Chat",
    value: LAB_CHAT,
  },
  {
    label: "Patient Chat",
    value: PATIENT_CHAT,
  },
];

export const PATIENT_INVOICING = "patients"
export const LAB_INVOICING = "labs"

export const TREATMENT_ORDER_KEY = "treatment"
export const APPLIANCES_ORDER_KEY = "appliances"
export const AWAITING_PAYMENT_STATUS = "Awaiting Payment"

export const PRODUCT_TYPE_PER_ALIGNER = "Per aligner"


export const MY_RESOURCES = "mine"
export const SHARED_RESOURCES = "shared"

export const resourcesTabOptions = [
  {
    label: "Patient",
    value: MY_RESOURCES,
  },
  {
    label: "Lab",
    value: SHARED_RESOURCES,
  },
];
