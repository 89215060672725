import AppEmpty from "components/shared/AppEmpty/AppEmpty";
import PartnerView from "./PartnerView/PartnerView";
import { useSelector } from "react-redux";
import { getClinicPartnerSelector } from "services/redux/selectors/clinicSelectors";

const Partner = () => {
  const clinicPartner = useSelector(getClinicPartnerSelector);
  return <>
  {clinicPartner ? <PartnerView clinicPartner={clinicPartner} /> :<AppEmpty/>
  }
  </>
};

export default Partner;
