import React from "react";
import { createRoot } from "react-dom/client";
import mixpanel from "mixpanel-browser";
import { datadogRum } from '@datadog/browser-rum';
import "./index.scss";
import App from "./App";
import "./config/sentry";

if (process.env.REACT_APP_STAGE === 'prod' && process.env.REACT_APP_COMPANY_TYPE=='5') {
  datadogRum.init({
    applicationId: '132ec16c-be24-46d2-99f7-70b0465a0213',
    clientToken: 'pubbfc83017e73ab9ea7d14e90165f9423b',
    service: 'smilealign-clinic-portal',
    env: 'production',
    site: 'datadoghq.eu',
    version: '1.0.0',
    trackUserInteractions: true,
    trackResources: true,
    defaultPrivacyLevel: 'mask-user-input',
  });
}


mixpanel.init(process.env.REACT_APP_MIX_PANEL);

// if ("serviceWorker" in navigator) {
//   navigator.serviceWorker
//     .register("./firebase-messaging-sw.js")
//     .then(function(registration) {
//       console.log("Registration successful, scope is:", registration.scope);
//     })
//     .catch(function(err) {
//       console.log("Service worker registration failed, error:", err);
//     });
// }

// ReactDOM.render(
//   <React.StrictMode>
//     <App />
//   </React.StrictMode>,
//   document.getElementById("root")
// );

const root = createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
