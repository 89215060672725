import { ReactComponent as DownArrow } from "../../../assets/images/DownArrow.svg";
import { ReactComponent as Teeth18 } from "../../../assets/images/teeths/teeth18.svg";
import { ReactComponent as Teeth17 } from "../../../assets/images/teeths/teeth17.svg";
import { ReactComponent as Teeth16 } from "../../../assets/images/teeths/teeth16.svg";
import { ReactComponent as Teeth15 } from "../../../assets/images/teeths/teeth15.svg";
import { ReactComponent as Teeth14 } from "../../../assets/images/teeths/teeth14.svg";
import { ReactComponent as Teeth13 } from "../../../assets/images/teeths/teeth13.svg";
import { ReactComponent as Teeth12 } from "../../../assets/images/teeths/teeth12.svg";
import { ReactComponent as Teeth11 } from "../../../assets/images/teeths/teeth11.svg";

import { ReactComponent as Teeth21 } from "../../../assets/images/teeths/teeth21.svg";
import { ReactComponent as Teeth22 } from "../../../assets/images/teeths/teeth22.svg";
import { ReactComponent as Teeth23 } from "../../../assets/images/teeths/teeth23.svg";
import { ReactComponent as Teeth24 } from "../../../assets/images/teeths/teeth24.svg";
import { ReactComponent as Teeth25 } from "../../../assets/images/teeths/teeth25.svg";
import { ReactComponent as Teeth26 } from "../../../assets/images/teeths/teeth26.svg";
import { ReactComponent as Teeth27 } from "../../../assets/images/teeths/teeth27.svg";
import { ReactComponent as Teeth28 } from "../../../assets/images/teeths/teeth28.svg";

import { ReactComponent as Teeth48 } from "../../../assets/images/teeths/teeth48.svg";
import { ReactComponent as Teeth47 } from "../../../assets/images/teeths/teeth47.svg";
import { ReactComponent as Teeth46 } from "../../../assets/images/teeths/teeth46.svg";
import { ReactComponent as Teeth45 } from "../../../assets/images/teeths/teeth45.svg";
import { ReactComponent as Teeth44 } from "../../../assets/images/teeths/teeth44.svg";
import { ReactComponent as Teeth43 } from "../../../assets/images/teeths/teeth43.svg";
import { ReactComponent as Teeth42 } from "../../../assets/images/teeths/teeth42.svg";
import { ReactComponent as Teeth41 } from "../../../assets/images/teeths/teeth41.svg";

import { ReactComponent as Teeth31 } from "../../../assets/images/teeths/teeth31.svg";
import { ReactComponent as Teeth32 } from "../../../assets/images/teeths/teeth32.svg";
import { ReactComponent as Teeth33 } from "../../../assets/images/teeths/teeth33.svg";
import { ReactComponent as Teeth34 } from "../../../assets/images/teeths/teeth34.svg";
import { ReactComponent as Teeth35 } from "../../../assets/images/teeths/teeth35.svg";
import { ReactComponent as Teeth36 } from "../../../assets/images/teeths/teeth36.svg";
import { ReactComponent as Teeth37 } from "../../../assets/images/teeths/teeth37.svg";
import { ReactComponent as Teeth38 } from "../../../assets/images/teeths/teeth38.svg";

export const FDI_CHART = [
  {
    name: "18",
    universalName: "1",
    palmerName: "UR8",
    showName: "18",
    selected: false,
    content: <Teeth18 />,
    id: 18,
    visible: false,
  },
  {
    name: "17",
    universalName: "2",
    palmerName: "UR7",
    showName: "17",
    selected: false,
    content: <Teeth17 />,
    id: 17,
    visible: false,
  },
  {
    name: "16",
    universalName: "3",
    palmerName: "UR6",
    showName: "16",
    selected: false,
    content: <Teeth16 />,
    id: 16,
    visible: false,
  },
  {
    name: "15",
    universalName: "4",
    palmerName: "UR5",
    showName: "15",
    selected: false,
    content: <Teeth15 />,
    id: 15,
    visible: false,
  },
  {
    name: "14",
    universalName: "5",
    palmerName: "UR4",
    showName: "14",
    selected: false,
    content: <Teeth14 />,
    id: 14,
    visible: false,
  },
  {
    name: "13",
    universalName: "6",
    palmerName: "UR3",
    showName: "13",
    selected: false,
    content: <Teeth13 />,
    id: 13,
    visible: false,
  },
  {
    name: "12",
    universalName: "7",
    palmerName: "UR2",
    showName: "12",
    selected: false,
    content: <Teeth12 />,
    id: 12,
    visible: false,
  },
  {
    name: "11",
    universalName: "8",
    palmerName: "UR1",
    showName: "11",
    selected: false,
    content: <Teeth11 />,
    id: 11,
    visible: false,
  },
  {
    name: "21",
    universalName: "9",
    palmerName: "UL1",
    showName: "21",
    selected: false,
    content: <Teeth21 />,
    id: 21,
    visible: false,
  },
  {
    name: "22",
    universalName: "10",
    palmerName: "UL2",
    showName: "22",
    selected: false,
    content: <Teeth22 />,
    id: 22,
    visible: false,
  },
  {
    name: "23",
    universalName: "11",
    palmerName: "UL3",
    showName: "23",
    selected: false,
    content: <Teeth23 />,
    id: 23,
    visible: false,
  },
  {
    name: "24",
    universalName: "12",
    palmerName: "UL4",
    showName: "24",
    selected: false,
    content: <Teeth24 />,
    id: 24,
    visible: false,
  },
  {
    name: "25",
    universalName: "13",
    palmerName: "UL5",
    showName: "25",
    selected: false,
    content: <Teeth25 />,
    id: 25,
    visible: false,
  },
  {
    name: "26",
    universalName: "14",
    palmerName: "UL6",
    showName: "26",
    selected: false,
    content: <Teeth26 />,
    id: 26,
    visible: false,
  },
  {
    name: "27",
    universalName: "15",
    palmerName: "UL7",
    showName: "27",
    selected: false,
    content: <Teeth27 />,
    id: 27,
    visible: false,
  },
  {
    name: "28",
    universalName: "16",
    palmerName: "UL8",
    showName: "28",
    selected: false,
    content: <Teeth28 />,
    id: 28,
    visible: false,
  },
  {
    name: "48",
    universalName: "32",
    palmerName: "LR8",
    showName: "48",
    selected: false,
    content: <Teeth48 />,
    id: 48,
    visible: false,
  },
  {
    name: "47",
    universalName: "31",
    palmerName: "LR7",
    showName: "47",
    selected: false,
    content: <Teeth47 />,
    id: 47,
    visible: false,
  },
  {
    name: "46",
    universalName: "30",
    palmerName: "LR6",
    showName: "46",
    selected: false,
    content: <Teeth46 />,
    id: 46,
    visible: false,
  },
  {
    name: "45",
    universalName: "29",
    palmerName: "LR5",
    showName: "45",
    selected: false,
    content: <Teeth45 />,
    id: 45,
    visible: false,
  },
  {
    name: "44",
    universalName: "28",
    palmerName: "LR4",
    showName: "44",
    selected: false,
    content: <Teeth44 />,
    id: 44,
    visible: false,
  },
  {
    name: "43",
    universalName: "27",
    palmerName: "LR3",
    showName: "43",
    selected: false,
    content: <Teeth43 />,
    id: 43,
    visible: false,
  },
  {
    name: "42",
    universalName: "26",
    palmerName: "LR2",
    showName: "42",
    selected: false,
    content: <Teeth42 />,
    id: 42,
    visible: false,
  },
  {
    name: "41",
    universalName: "25",
    palmerName: "LR1",
    showName: "41",
    selected: false,
    content: <Teeth41 />,
    id: 41,
    visible: false,
  },
  {
    name: "31",
    universalName: "24",
    palmerName: "LL1",
    showName: "31",
    selected: false,
    content: <Teeth31 />,
    id: 31,
    visible: false,
  },
  {
    name: "32",
    universalName: "23",
    palmerName: "LL2",
    showName: "32",
    selected: false,
    content: <Teeth32 />,
    id: 32,
    visible: false,
  },
  {
    name: "33",
    universalName: "22",
    palmerName: "LL3",
    showName: "33",
    selected: false,
    content: <Teeth33 />,
    id: 33,
    visible: false,
  },
  {
    name: "22",
    universalName: "21",
    palmerName: "LL4",
    showName: "34",
    selected: false,
    content: <Teeth34 />,
    id: 34,
    visible: false,
  },
  {
    name: "35",
    universalName: "20",
    palmerName: "LL5",
    showName: "35",
    selected: false,
    content: <Teeth35 />,
    id: 35,
    visible: false,
  },
  {
    name: "36",
    universalName: "19",
    palmerName: "LL6",
    showName: "36",
    selected: false,
    content: <Teeth36 />,
    id: 36,
    visible: false,
  },
  {
    name: "37",
    universalName: "18",
    palmerName: "LL7",
    showName: "37",
    selected: false,
    content: <Teeth37 />,
    id: 37,
    visible: false,
  },
  {
    name: "38",
    universalName: "17",
    palmerName: "LL8",
    showName: "38",
    selected: false,
    content: <Teeth38 />,
    id: 38,
    visible: false,
  },
];

export const showTeethName = (toothNo, structure) => {
  if (toothNo) {
    const filtered = FDI_CHART.filter((item) => item.name == toothNo);
  let name = toothNo;
  if (structure === "universal") {
    name = filtered[0].universalName;
  } else {
    name=  filtered[0].palmerName;
  }
   return name
  }
  else{
    return ""
  }
};


export function removeUnderscore(text) {
  // console.log("text", text)
  // Remove underscores
  const withoutUnderscore = text.replace(/_/g, ' ');

  // Capitalize the first letter of each word
  const capitalizedText = withoutUnderscore.replace(/\b\w/g, (match) =>
    match.toUpperCase()
  );

  return capitalizedText;
}