import { useState } from "react";
import { Row, DatePicker, Space, Button } from "antd";
import dayjs from "dayjs";
const { RangePicker } = DatePicker;

const FilterDataRangePicker = ({
  clearFilters,
  confirm,
  handleDateFilter,
}) => {
  const [isFilterSet, setIsFilterSet] = useState(false);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  const dateChangeHandler = (e) => {
    setIsFilterSet(true);
    setStartDate(e[0] ? e[0] : null);
    setEndDate(e[1] ? e[1] : null);
  };
  const handleReset = (clearFilters) => {
    clearFilters();
    setStartDate(null);
    setEndDate(null);
    setIsFilterSet(false);
    handleDateFilter(confirm);
    // this.setState({ searchText: "" });
  };
  const disabledDate = (current) => {
    // Disable dates from today and onwards
    return current && current >= dayjs().endOf("day");
  };

  return (
    <div style={{ padding: 8 }}>
      <Row>
        <RangePicker
          onChange={dateChangeHandler}
          value={[startDate, endDate]}
          disabledDate={disabledDate}
          format="DD-MMM-YYYY"
        />
      </Row>
      <Space
        style={{ marginTop: 4, justifyContent: "space-between", width: "100%" }}
      >
        <Button
          onClick={() => handleReset(clearFilters)}
          size="small"
          style={{ width: 90, border: "none" }}
          disabled={!isFilterSet}
        >
          Reset
        </Button>
        <Button
          onClick={() =>
            handleDateFilter(
              confirm,
              startDate.format("YYYY-MM-DD"),
              endDate.format("YYYY-MM-DD")
            )
          }
          size="small"
          type="primary"
          style={{ float: "right" }}
        >
          Ok
        </Button>
      </Space>
    </div>
  );
};

export default FilterDataRangePicker;
