import { useState, useEffect, useMemo } from "react";
import { Card, Row, Col, Avatar, Typography, Select, Tooltip,Anchor } from "antd";
import moment from "moment";

import {
  UserOutlined,
  VideoCameraOutlined,
  CalendarOutlined,
  HomeOutlined,
} from "@ant-design/icons";

import "../../../assets/styles/components/appointmentDetailsCardComponent.scss";
// import { googleSignInButton } from "aws-amplify";
import { convertToMinutes } from "../../../utilities/commonMethod";
import { AnchorTag } from "components/shared/AnchorTag/index";
// import { createDateTime, pickStatusColor } from "../../utilities/commonMethod";
import { useHistory } from "react-router-dom";
import { CalenderIcon, HomeIcon } from "components/shared/Icon/index";
import { CameraIcon } from "components/shared/Icon/CameraIcon";
import {
  getClinicManagementSelector,
  getClinicProfileSelector
} from "services/redux/selectors/clinicSelectors";
import { useSelector } from "react-redux";
import { displayDate } from "components/shared/Date/Date";

const { Title } = Typography;
const { Option } = Select;

const AppointmentDetailsCardComponent = ({
  selectedAppointment,
  clinicians,
  setSelectedClinician,
  appointmentStatus,
  defaultClinicianId,
  selectedClinician,
  updateClinician,
}) => {
  const clinic = useSelector(getClinicProfileSelector);
  const [changeDoctormode, setChangeDoctorMode] = useState(false);




  const history = useHistory();

  useEffect(() => {
    if (!selectedAppointment.clinician_id)
      setSelectedClinician(defaultClinicianId);
    else {
      setSelectedClinician(selectedAppointment.clinician_id);
    }
  }, [selectedAppointment]);


  

  useEffect(() => {
    return () => {
      setChangeDoctorMode(false);
    };
  }, [selectedAppointment]);


  const appointmentDateTime = useMemo(() => {
    const startMinutes = convertToMinutes(
      selectedAppointment.appointment_start_time
    );
    const endMinutes = convertToMinutes(
      selectedAppointment.appointment_end_time
    );

    return {
      date: displayDate(selectedAppointment.appointment_date),
      startTime: moment(selectedAppointment.appointment_date)
        .startOf("day")
        .add(startMinutes, "minutes")
        .format("hh:mm A"),
      endTime: moment(selectedAppointment.appointment_date)
        .startOf("day")
        .add(endMinutes, "minutes")
        .format("hh:mm A"),
    };
  }, [selectedAppointment]);

  return (
    <div>
      <Card className="appointment-card">
        <Row>
          <Avatar size={64} icon={<UserOutlined />} />
          <div
            style={{
              textAlign: "left",
              marginLeft: "1rem",
            }}
          >
            <Row style={{ height: "2.5rem" }}>
              <Tooltip
                placement={"bottom"}
                level={3}
                title={
                  selectedAppointment.patient_first_name +
                  " " +
                  selectedAppointment.patient_last_name
                }
              >
                <Title className="card-headings" level={3}>
                  {selectedAppointment.patient_first_name +
                    " " +
                    selectedAppointment.patient_last_name}
                </Title>
              </Tooltip>
              <div style={{ marginTop: "0.4rem", marginLeft: "0.7rem" }}>
                <AnchorTag
                  text={"View Profile"}
                  onUrlClicked={() => {
                    history.push(`/clinic/patient/${selectedAppointment.patient_id}`);
                  }}
                />
              </div>
            </Row>
            <Row>
              <p className="card-sub-headings">
              {selectedAppointment.clinician_country_code_name ? `${selectedAppointment.clinician_country_code_name}-` :""}
                {selectedAppointment.patient_phone_number}
              </p>
            </Row>
          </div>
        </Row>
        <Row style={{ marginTop: "2rem" }} justify="space-between">
          <Col span={10} style={{ textAlign: "left" }}>
            <div>
              <p className="card-sub-headings">Appointment Duration</p>
            </div>
            <Row>
              <Col>
              <CalenderIcon/>
                {/* <CalendarOutlined className="card-icon" /> */}
              </Col>

              <Col style={{ marginLeft: "0.5rem" }}>
                <p className="card-sub-heading-children">
                  {`${appointmentDateTime?.startTime || ""} - ${
                    appointmentDateTime?.endTime || ""
                  }`}
                </p>
                <p
                  className="card-sub-headings"
                  style={{ marginTop: "-0.8rem" }}
                >
                  {appointmentDateTime?.date}
                </p>
              </Col>
            </Row>
          </Col>
          <Col span={10} offset={4}>
            <div>
              <p className="card-sub-headings">Appointment Type</p>
            </div>
            <Row>
              <Col>
                {selectedAppointment.appointment_type_id === 1 ? (
                  <HomeIcon/>
                ) : (
                  <CameraIcon/>
                )}
              </Col>

              <Col style={{ marginLeft: "0.5rem" }}>
                <p className="card-sub-heading-children">
                  {" "}
                  {selectedAppointment.appointment_type}
                </p>
              </Col>
            </Row>
          </Col>
        </Row>

        <Row style={{ marginTop: "2rem" }} justify="space-between">
          <Col span={10} style={{ textAlign: "left" }}>
            <div>
              <p className="card-sub-headings">Appointment Name</p>
            </div>
            <div>
              <p className="card-sub-heading-children">
                {selectedAppointment.appointment_name}
              </p>
            </div>
          </Col>
          <Col
            span={10}
            offset={4}
            style={{
              textAlign: "left",
            }}
          >
            <div>
              <p className="card-sub-headings">Clinician</p>
            </div>
            <div>
              {(appointmentStatus === "PENDING" ||
                appointmentStatus === "PATIENT_RESCHEDULE") && (
                  <div>
                  <p className="card-sub-heading-children">
                  {clinic?.clinic_name}
                  </p>
                </div>
                // <Select
                // size="large"
                //   style={{ width: 150 }}
                //   onChange={handleSelectChange}
                //   defaultValue={defaultClinicianId}
                // >
                //   {clinicians &&
                //     clinicians.map((clinician) => {
                //       return (
                //         <Option value={clinician.clinician_id}>
                //           {clinician.clinician_name}
                //         </Option>
                //       );
                //     })}
                // </Select>
              )}
              {(appointmentStatus === "CANCELLED" ||
                appointmentStatus === "ATTENDED" ||
                appointmentStatus === "NOSHOW") && (
                <div>
                  <p className="card-sub-heading-children">
                    {clinic?.clinic_name}
                  </p>
                </div>
              )}
              {appointmentStatus === "APPROVED" && (
                <div>
                  {!changeDoctormode ? (
                    <div>
                      <p className="card-sub-heading-children">
                      {clinic?.clinic_name}
                      </p>
                      {/* <p
                        className="change-clinician"
                        style={{color:primaryColor}}
                        onClick={() => setChangeDoctorMode(true)}
                      >
                        Change Clinician
                      </p> */}
                    </div>
                  ) : (<></>
                    // <Select
                    // size="large"
                    //   style={{ width: 150 }}
                    //   onChange={handleDoctorEditSelectChange}
                    //   defaultValue={selectedClinician}
                    // >
                    //   {clinicians &&
                    //     clinicians.map((clinician) => {
                    //       return (
                    //         <Option value={clinician.clinician_id}>
                    //           {clinician.clinician_name}
                    //         </Option>
                    //       );
                    //     })}
                    // </Select>
                  )}
                </div>
              )}
            </div>
          </Col>
        </Row>
      </Card>
    </div>
  );
};

export default AppointmentDetailsCardComponent;
