import { Row, Col, Divider } from "antd";
import dayjs from "dayjs";
import { companyType } from "colors-config";
import { displayDate } from "components/shared/Date/Date";

const PrescriptionHeader = ({ prescriptionPDFObj, selectedVersion }) => {
  return (
    <div
      className={`prescriptionPDF-header ${companyType === 5 ? "smileAlign-header" : ""}
      ${companyType === 6 ? "ias-header" : ""}
      ${companyType === 7 ? "aqua-header" : ""}
      ${companyType === 8 ? "brushbar-header" : ""}
      `}
    >
      <Row align="middle" justify="space-between">
        <Col lg={16}>
          <h1>Prescription</h1>
          <h2>Version {selectedVersion}.0</h2>
        </Col>
        <Col lg={4}>
          <b>
            {/* {prescriptionPDFObj?.createdAt} */}
            Updated By: {prescriptionPDFObj.updatedFrom}
          </b>
        </Col>
        <Col lg={4}>
          <b>
            {/* {prescriptionPDFObj?.createdAt} */}
            Date: {displayDate(dayjs())}
          </b>
        </Col>
        <Divider />
      </Row>
    </div>
  );
};

export default PrescriptionHeader;
