export const LOWER_JAW = [
  {
    fdiName: "48",
    universalName: "32",
    palmerName: "LR8",
    showName: "48",
    selection: true,
    selected: false,

    id: 48,
    visible: false,
  },
  {
    fdiName: "47",
    universalName: "31",
    palmerName: "LR7",
    showName: "47",
    selection: true,
    selected: false,
    id: 47,
    visible: false,
  },
  {
    fdiName: "46",
    universalName: "30",
    palmerName: "LR6",
    showName: "46",
    selection: true,
    selected: false,
    id: 46,
    visible: false,
  },
  {
    fdiName: "45",
    universalName: "29",
    palmerName: "LR5",
    showName: "45",
    selection: true,
    selected: false,
    id: 45,
    visible: false,
  },
  {
    fdiName: "44",
    universalName: "28",
    palmerName: "LR4",
    showName: "44",
    selection: true,
    selected: false,
    id: 44,
    visible: false,
  },
  {
    fdiName: "43",
    universalName: "27",
    palmerName: "LR3",
    showName: "43",
    selection: true,
    id: 43,
    selected: false,
    visible: false,
  },
  {
    fdiName: "42",
    universalName: "26",
    palmerName: "LR2",
    showName: "42",
    selection: true,
    id: 42,
    selected: false,
    visible: false,
  },
  {
    fdiName: "41",
    universalName: "25",
    palmerName: "LR1",
    showName: "41",
    selection: true,
    selected: false,
    id: 41,
    visible: false,
  },
  {
    fdiName: "31",
    universalName: "24",
    palmerName: "LL1",
    showName: "31",
    selection: true,
    selected: false,
    id: 31,
    visible: false,
  },
  {
    fdiName: "32",
    universalName: "23",
    palmerName: "LL2",
    showName: "32",
    selection: true,
    selected: false,
    id: 32,
    visible: false,
  },
  {
    fdiName: "33",
    universalName: "22",
    palmerName: "LL3",
    showName: "33",
    selection: true,
    selected: false,
    id: 33,
    visible: false,
  },
  {
    fdiName: "34",
    universalName: "21",
    palmerName: "LL4",
    showName: "34",
    selection: true,
    selected: false,
    id: 34,
    visible: false,
  },
  {
    fdiName: "35",
    universalName: "20",
    palmerName: "LL5",
    showName: "35",
    selection: true,
    selected: false,
    id: 35,
    visible: false,
  },
  {
    fdiName: "36",
    universalName: "19",
    palmerName: "LL6",
    showName: "36",
    selection: true,
    selected: false,
    id: 36,
    visible: false,
  },
  {
    fdiName: "37",
    universalName: "18",
    palmerName: "LL7",
    showName: "37",
    selection: true,
    id: 37,
    visible: false,
    selected: false,
  },
  {
    fdiName: "38",
    universalName: "17",
    palmerName: "LL8",
    showName: "38",
    selection: false,
    id: 38,
    visible: false,
    selected: false,
  },
];

export const UPPER_JAW = [
  {
    fdiName: "18",
    universalName: "1",
    palmerName: "UR8",
    showName: "18",
    selection: true,
    id: 18,
    visible: false,
    selected: false,
  },
  {
    fdiName: "17",
    universalName: "2",
    palmerName: "UR7",
    showName: "17",
    selection: true,
    id: 17,
    visible: false,
    selected: false,
  },
  {
    fdiName: "16",
    universalName: "3",
    palmerName: "UR6",
    showName: "16",
    selection: true,
    id: 16,
    visible: false,    selected: false,
  },
  {
    fdiName: "15",
    universalName: "4",
    palmerName: "UR5",
    showName: "15",
    selection: true,
    selected: false,
    id: 15,
    visible: false,
  },
  {
    fdiName: "14",
    universalName: "5",
    palmerName: "UR4",
    showName: "14",
    selection: true,
    selected: false,
    id: 14,
    visible: false,
  },
  {
    fdiName: "13",
    universalName: "6",
    palmerName: "UR3",
    showName: "13",
    selection: true,
    selected: false,
    id: 13,
    visible: false,
  },
  {
    fdiName: "12",
    universalName: "7",
    palmerName: "UR2",
    showName: "12",
    selection: true,
    selected: false,
    id: 12,
    visible: false,
  },
  {
    fdiName: "11",
    universalName: "8",
    palmerName: "UR1",
    showName: "11",
    selection: true,
    selected: false,
    id: 11,
    visible: false,
  },
  {
    fdiName: "21",
    universalName: "9",
    palmerName: "UL1",
    showName: "21",
    selection: true,
    selected: false,
    id: 21,
    visible: false,
  },
  {
    fdiName: "22",
    universalName: "10",
    palmerName: "UL2",
    showName: "22",
    selection: true,
    selected: false,
    id: 22,
    visible: false,
  },
  {
    fdiName: "23",
    universalName: "11",
    palmerName: "UL3",
    showName: "23",
    selection: true,
    selected: false,
    id: 23,
    visible: false,
  },
  {
    fdiName: "24",
    universalName: "12",
    palmerName: "UL4",
    showName: "24",
    selection: true,
    selected: false,
    id: 24,
    visible: false,
  },
  {
    fdiName: "25",
    universalName: "13",
    palmerName: "UL5",
    showName: "25",
    selection: true,
    selected: false,
    id: 25,
    visible: false,
  },
  {
    fdiName: "26",
    universalName: "14",
    palmerName: "UL6",
    showName: "26",
    selection: true,
    id: 26,
    visible: false,
    selected: false,
  },
  {
    fdiName: "27",
    universalName: "15",
    palmerName: "UL7",
    showName: "27",
    selection: true,
    selected: false,
    id: 27,
    visible: false,
  },
  {
    fdiName: "28",
    universalName: "16",
    palmerName: "UL8",
    showName: "28",
    selection: false,
    id: 28,
    visible: false,
    selected: false,
  },
];
