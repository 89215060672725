import { returnErrors } from "./errorActions";
import { Auth } from "aws-amplify";
import { loadClinic, clearClinic } from "./clinicActions";


import {
  USER_LOADED,
  USER_LOADING,
  AUTH_ERROR,
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  LOGOUT_SUCCESS,
  SET_TOKEN,
} from "./types";
import { message } from "antd";
import { getClinicFirstLogin, updateClinicOnboardingProgressStatus } from "api/clinic";
import { ONBOARDING_PROGRESS } from "../../../constants";
import { clearSentryUserContext, setSentryUserContext } from "libs/sentry";


export const tokenExpired = () => async (dispatch) => {
  dispatch({
    type: AUTH_ERROR,
  });
};
// Check token & load user
export const loadUser = () => async (dispatch, getState) => {
  // User loading
  dispatch({ type: USER_LOADING });

  try {

    const user = await Auth.currentAuthenticatedUser();
    localStorage.setItem("idToken", user.signInUserSession.idToken.jwtToken);

    await dispatch({
      type: USER_LOADED,
      payload: user,
    });
    setSentryUserContext({ user });
    await dispatch(loadClinic());
  } catch (error) {
    await dispatch(returnErrors(error, error.code));
    await dispatch({
      type: AUTH_ERROR,
    });
  }
};

export const setToken = (token) => async (dispatch, getState) => {
  localStorage.setItem("idToken", token);
  await dispatch({ type: SET_TOKEN, payload: { token } });
};

// Login User
export const login = (
  username,
  password,
  routerHistory,
  setIsSpinning
) => async (dispatch) => {
  try {
    const user = await Auth.signIn(username, password);
    localStorage.setItem("idToken", user.signInUserSession.idToken.jwtToken);

    const res = await getClinicFirstLogin({ clinicEmail: username }, {
      "Authorization": "Bearer " + user.signInUserSession.idToken.jwtToken
    })

    setIsSpinning(false);

    const isFirstLogin = res?.data?.body?.firstLogin === 0 ? false : true;
    const onboardingStep = res?.data?.body?.onboardingStep;
    const onboardingStatus = res?.data?.body?.clinic_onboarded_status;
    user.onboardingStep = onboardingStep
    const isClinicAvailability = res?.data?.body?.clinic_availability;

    await dispatch({
      type: LOGIN_SUCCESS,
      payload: user,
    });

    await dispatch(loadClinic());

    if (isFirstLogin) {
      if(onboardingStep){
        let step="";
        if(onboardingStep==='profile'){
          step=""
          await updateClinicOnboardingProgressStatus({ email: username, status: ONBOARDING_PROGRESS.ONBOARDING_IN_PROGRESS})
        }
        else if(onboardingStep==='help-support'){
          step='help-support'
        }
        else if(onboardingStep==='clinic-setup'){
          step='clinic-setup'
        }
        routerHistory.push(`/onboarding${step ? `/${step}` : ""}`);
      }
      else{
        await updateClinicOnboardingProgressStatus({ email: username, status: ONBOARDING_PROGRESS.ONBOARDING_IN_PROGRESS})
        routerHistory.push("/welcome-page");
      }
      
    } else {
      routerHistory.push("/clinic/patients");
      // if (isClinicAvailability) {
      //   routerHistory.push("/clinic/scheduling/pending");
      // }
      // else{
      //   routerHistory.push("/clinic/patients");
      // }
   
    }
    // window.location.reload();
  } catch (error) {
    console.log("error signing in", error.code);
    setIsSpinning(false);

    message.error(error.message);

    await dispatch(returnErrors(error, error.code));
    await dispatch({
      type: LOGIN_FAIL,
    });
  }
};

// Logout User
export const logout = () => async (dispatch) => {
  try {
    await Auth.signOut();

    dispatch({
      type: LOGOUT_SUCCESS,
    });
    clearSentryUserContext();
    dispatch(clearClinic());
    localStorage.clear();
    window.location.reload();
  } catch (error) {
    console.log("error signing out: ", error);
  }
};
