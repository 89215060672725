import { useMemo, useRef, useState } from "react";
import { Row, Col, Card, Avatar, Divider, Tabs, Button } from "antd";
import "../../../assets/styles/components/patient-journey/patientQuestionnaireComponent.scss";
import {
  DownloadOutlined,
  UserOutlined,
  CalendarOutlined,
  MobileOutlined,
  MailOutlined,
} from "@ant-design/icons";
import logo from "../../../assets/images/smile-logo-print.jpeg";
import { useReactToPrint } from "react-to-print";
import moment from "moment";
import classes from "../../../assets/styles/routes/assesmentForm.module.scss";
import { ReactComponent as AddressIcon } from "../../../assets/images/address_icon.svg";
import { primaryColor } from "colors-config";
import { DownloadIcon } from "components/shared/Icon/index";
import ConsentForm from "./consentForm";
import { displayDate } from "components/shared/Date/Date";

const iconCssProps = {
  color: primaryColor,
  fontSize: "20px",
};

function PatientQuestionnaireComponent({
  patientResponses,
  clinic,
  patientInfoState,
  imageStageTypes,
}) {
  const [showConsentForm, setShowConsentForm] = useState("smile");
  const signedStamp = useMemo(() => {
    if (patientResponses && patientResponses.length) {
      const firstQuestion = patientResponses[0];
      return firstQuestion[0]?.created_at
        ? displayDate(firstQuestion[0]?.created_at,"DD-MMM-YYYY | hh:mm")
        : "";
    }
    return "";
  }, [patientResponses]);

  const patientAddress = useMemo(() => {
    return `${patientInfoState?.patient_address || ""}${
      patientInfoState?.patient_address && patientInfoState?.patient_city ? "," : ""
    } ${patientInfoState?.patient_city || ""}${
      patientInfoState?.patient_county && patientInfoState?.patient_city ? "," : ""
    } ${patientInfoState?.patient_county || ""}${
      patientInfoState?.patient_county && patientInfoState?.patient_postal_code ? "," : ""
    } ${patientInfoState?.patient_postal_code || ""}${
      patientInfoState?.patient_country && patientInfoState?.patient_postal_code ? "," : ""
    } ${patientInfoState?.patient_country || ""}`;
  }, [patientInfoState]);

  const clinicLongAddress = useMemo(() => {
    return `${clinic?.address || ""}${clinic?.address && clinic?.clinicCity ? "," : ""} ${
      clinic?.clinicCity || ""
    }${clinic?.clinicCity && clinic?.clinicCounty ? "," : ""} ${clinic?.clinicCounty || ""}${
      clinic?.clinicCounty && clinic?.zipCode ? "," : ""
    } ${clinic?.zipCode || ""}${clinic?.country && clinic?.zipCode ? "," : ""} ${
      clinic?.country || ""
    }`;
  }, [clinic]);

  const formRef = useRef();

  const handleDownload = useReactToPrint({
    content: () => {
      document.querySelectorAll(".print-part-element").forEach(function (container) {
        if (container) {
          container.style.display = "block";
        }
      });
      const printImageContainer = document.getElementById("print-form-images-container");
      printImageContainer.style.display = "grid";

      return formRef.current;
    },
    onAfterPrint: () => {
      document.querySelectorAll(".print-part-element").forEach(function (container) {
        if (container) {
          container.style.display = "none";
        }
      });
      const printImageContainer = document.getElementById("print-form-images-container");
      printImageContainer.style.display = "none";
    },
  });

  const displayInitialImages = () => {
    const imageStageTypeKeys = Object.keys(imageStageTypes);
    if (imageStageTypeKeys && imageStageTypeKeys.length) {
      const imagesList = imageStageTypes[imageStageTypeKeys[0]];
      if (imagesList?.length) {
        return imagesList.map((image, index) => {
          return (
            <div className="print-form-images-col" key={index}>
              <img className="print-form-images" src={image.imageUrl} />
            </div>
          );
        });
      }
    }
    return "";
  };

  const createPatientData = () => {
    return patientResponses?.map((patientResObj, index) => {
      return (
        <Col span={11} style={{ height: "8rem" }} offset={index % 2 == 0 ? 0 : 2}>
          <Row>
            <p className="patient-question">{patientResObj?.patient_question_text || ""}</p>
          </Row>
          <Row>
            <p className="patient-response">{patientResObj?.patient_answer || ""}</p>
          </Row>
          <Row>
            <p className="patient-response">
              {patientResObj?.patient_additional_details_answer || ""}
            </p>
          </Row>
        </Col>
      );
    });
  };

  const items = [
    {
      key: "smile",
      label: "Smile Assessment Form",
      children: "",
    },
    {
      key: "patient",
      label: "Patient Consent Form",
      children: "",
    },
  ];

  const onChange = (key) => {
    setShowConsentForm(key);
  };

  return (
    <Card
      style={{
        width: "100%",
        minHeight: "50rem",
        maxHeight: "50rem",
        textAlign: "left",
        boxShadow: "0px 10px 16px rgba(0, 0, 0, 0.02)",
        borderRadius: "8px",
        padding: 0,
      }}
      className="print-assessment-card"
      title={
        <Tabs
          defaultActiveKey="1"
          className="tab-assessment-card"
          items={items}
          onChange={onChange}
        />
      }
      // extra={patientResponses.length > 0 && <DownloadIcon onClick={() => handleDownload()} />}
    >
      {showConsentForm === "smile" ? (
       <div>
          <div className="print-assesment-container">
            {patientResponses.length > 0 ? 
               <div className="consent-form-button-container">
               <Button onClick={handleDownload} type="link" className="download-button-consent">
                 Download
                 <DownloadOutlined />
               </Button>
             </div>:null
          }
         
          </div>
         <div ref={formRef}>
         <div className="print-smile-assessment ">
           <div style={{ display: "none" }} className="print-part-element">
             <div className={classes["assessment-header"]}>
               <div style={{ alignSelf: "center" }}>
                 {signedStamp && (
                   <div className={`${classes["info-type"]} ${classes["tertiary-text"]}`}>
                     signed- {signedStamp}
                   </div>
                 )}
                 <div style={{ fontSize: "28px", fontWeight: "400" }}>Smile Assessment</div>
               </div>
               <div style={{ justifySelf: "flex-end" }}>
                 <img src={logo} alt="smile-genius logo" />
               </div>
             </div>
           </div>

           <div style={{ display: "none" }} className="padding-class print-part-element">
             <div className={classes["patient-info"]}>
               <div
                 style={{
                   display: "grid",
                   gridTemplateColumns: "1fr 1fr",
                   padding: "1rem",
                 }}
               >
                 <div className={classes["patient-col"]}>
                   <Avatar
                     style={{
                       backgroundColor: "#FAFAFA",
                       border: "2px solid rgb(221 217 217)",
                       minWidth: "80px",
                     }}
                     size={80}
                     icon={<UserOutlined style={{ color: "#252525cc" }} />}
                   />
                   <div style={{ paddingLeft: "1rem" }}>
                     <h1 className={classes["first-heading"]}>{`${
                       patientInfoState?.patient_first_name || ""
                     } ${patientInfoState?.patient_last_name || ""}`}</h1>
                     <h2 className={classes["sub-heading"]}>{patientInfoState?.patient_id}</h2>
                   </div>
                 </div>

                 <div className={classes["clinic-info"]}>
                   <div>{clinic?.clinicName || ""}</div>
                   <div>{clinicLongAddress || ""}</div>
                   <div>
                     {clinic?.clinicCountryCode || ""}
                     {clinic?.clinicPhoneNumber || ""}
                   </div>
                   <div>{clinic?.clinicEmail || ""}</div>
                 </div>
               </div>
               <Divider style={{ margin: 0 }} className={classes["color-divider"]} />
               <div style={{ padding: "1rem 2rem" }}>
                 <Row gutter={[10, 10]}>
                   <Col md={7}>
                     <AssesmentPatientInfo
                       type={"DOB"}
                       icon={<CalendarOutlined style={iconCssProps} />}
                       value={
                         patientInfoState?.patient_date_of_birth
                           ? displayDate(patientInfoState?.patient_date_of_birth)
                           : "-"
                       }
                     />
                   </Col>
                   <Col md={7}>
                     <AssesmentPatientInfo
                       type="Mobile No"
                       icon={<MobileOutlined style={iconCssProps} />}
                       value={patientInfoState?.patient_phone_number || "-"}
                       border={true}
                     />
                   </Col>
                   <Col md={10}>
                     <AssesmentPatientInfo
                       icon={<MailOutlined style={iconCssProps} />}
                       type="Email Id"
                       value={patientInfoState?.patient_email || "-"}
                       border={true}
                     />
                   </Col>
                 </Row>
                 <Row>
                   <Col>
                     <AssesmentPatientInfo
                       icon={<AddressIcon style={iconCssProps} />}
                       type="Address"
                       value={patientAddress || "-"}
                     />
                   </Col>
                 </Row>
               </div>
             </div>
           </div>

           <div className="padding-class" style={{padding:"0 1rem"}}>
             <Row className="questionnaire-responses-component ">
               {patientResponses.length
                 ? createPatientData()
                 : "Smile Assessment Form is yet to be completed by the patient."}
             </Row>
           </div>

           <div
             className="print-form-images-container"
             id="print-form-images-container"
             style={{ display: "none" }}
           >
             {displayInitialImages()}
           </div>
         </div>
       </div>
       </div>
      ) : null}
      {showConsentForm === "patient" ? <ConsentForm clinicLongAddress={clinicLongAddress} clinic={clinic} patientDetails={patientInfoState} /> : null}
    </Card>
  );
}

export default PatientQuestionnaireComponent;

const AssesmentPatientInfo = ({ type, icon, value, border }) => {
  const borderStyle = border ? { borderLeft: "2px solid #ccccccb8", paddingLeft: "1rem" } : null;
  return (
    <div style={borderStyle} className={classes["assesment-inf-cells"]}>
      <div className={classes["info-type"]}>{type}</div>
      <div>
        <span>{icon}</span>
        <span style={{ marginLeft: "10px", fontWeight: "600" }}>{value}</span>
      </div>
    </div>
  );
};
