import { useState } from "react";
import { Row, Col, Flex, Popconfirm } from "antd";
import { ReactComponent as EditIcon } from "assets/images/edit_icon.svg";
import { DeleteOutlined } from "@ant-design/icons";
import styles from "./view.module.scss";
import { useSelector } from "react-redux";
import AppointmentModel from "../AppointmentModel/AppointmentModel";
import AppButton from "components/shared/AppButton/AppButton";
import { getAppointmentSlotName, getCurrencyName } from "utilities/settings";
import { getClinicCurrencySelector } from "services/redux/selectors/clinicSelectors";

const TreatmentProductsView = ({
  viewAppointmentsList,
  clinicDurationList,
  setViewAppointmentsList,
  setAppointmentEditObj,
}) => {
  //  console.log("appointmentsListappointmentsList", appointmentsList)
  const [editAppointment, setEditAppointment] = useState(null);
  const [showAppointmentModal, setShowAppointmentModal] = useState(false);
  const clinicCurrenciesList = useSelector(getClinicCurrencySelector) || [];

  const onDelete = (id) => {
    const appointments = [...viewAppointmentsList];
    const finalData = appointments.filter((obj) => obj.appointment_id !== id);
    // console.log("finalData", finalData)
    setViewAppointmentsList(finalData);
    // setClinicAppointments(clinicAppointments.filter((_, i) => i !== index));
  };

  const onCancel = () => {
    setShowAppointmentModal(false);
    setEditAppointment(null);
  };

  const onEdit = (product) => {
    setShowAppointmentModal(true);
    setEditAppointment(product);
  };
  return (
    <div className={styles["appointment-view-container"]}>
      <div className={styles["appointments-products-view"]}>
        <Row align="middle" gutter={[16, 16]}>
          <Col lg={6}>
            <label className={styles["form-info-label"]}>
              Appointment Name
            </label>
          </Col>
          <Col lg={6}>
            {" "}
            <label className={styles["form-info-label"]}>Description</label>
          </Col>
          <Col lg={4}>
            <label className={styles["form-info-label"]}>Duration</label>
          </Col>

          <Col lg={6}>
            <label className={styles["form-info-label"]}>Price</label>
          </Col>
        </Row>
        {viewAppointmentsList?.map((appointment, index) => {
          return (
            <div key={index} className={styles["appointment-product-view"]}>
              <Row align="middle" gutter={[16, 16]}>
                <Col lg={6}>
                  <p className={styles["form-info-value"]}>
                    {appointment.appointment_name}
                  </p>
                </Col>
                <Col lg={6}>
                {appointment?.description ? <p className={styles["form-info-value"]}>
                  {appointment.description}
                  </p> : <p className={styles["form-info-desc"]}>
                    --
                  </p>}
                  
                </Col>
                <Col lg={4}>
                  <p className={styles["form-info-value"]}>
                    {" "}
                    {appointment.duration}
                    {getAppointmentSlotName(
                      appointment.slot_duration_id,
                      clinicDurationList
                    )}
                  </p>
                </Col>
                <Col lg={4}>
                  <p className={styles["form-info-value"]}>
                    {appointment.free
                      ? "Free"
                      : `${getCurrencyName(
                          appointment.slot_currency_id,
                          clinicCurrenciesList
                        )} ${appointment.price}`}
                  </p>
                </Col>
                <Col lg={4}>
                  <Flex>
                    <EditIcon
                      fill={"#77849E"}
                      style={{
                        width: "14px",
                        margin: "0px 30px",
                        cursor: "pointer",
                      }}
                      onClick={() => onEdit(appointment)}
                    />
                    <Popconfirm
                      title="Delete"
                      description="Are you sure you want to delete this appointment? "
                      okText="Yes"
                      cancelText="No"
                      onConfirm={(e) => {
                        onDelete(appointment.appointment_id);
                      }}
                      onCancel={() => {}}
                    >
                      <DeleteOutlined
                        style={{
                          fontSize: "18px",
                          float: "right",
                          color: "#77849E",
                        }}
                      />
                    </Popconfirm>
                  </Flex>
                </Col>
              </Row>
            </div>
          );
        })}
        {!viewAppointmentsList?.length && (
          <Row align="middle" gutter={[16, 16]}>
            <Col lg={6}>
              <div className={styles["empty-value"]} />
            </Col>
            <Col lg={6}>
              {" "}
              <div className={styles["empty-value"]} />
            </Col>
            <Col lg={4}>
              <div className={styles["empty-value"]} />
            </Col>

            <Col lg={5}>
              <div className={styles["empty-value"]} />
            </Col>
            <Col lg={3}>
              <AppButton
                type="primary"
                shape="round"
                onClick={() => {
                  setShowAppointmentModal(true);
                  setEditAppointment(null);
                }}
                block
              >
                Add +
              </AppButton>
            </Col>
          </Row>
          // <Empty
          //   description={<Typography.Text>No Clinic Appointments</Typography.Text>}
          // ></Empty>
        )}
      </div>
      {viewAppointmentsList.length ? (
        <Row align="middle" justify="space-between">
          <Col>
            <AppButton
              shape="round"
              onClick={() => {
                setShowAppointmentModal(true);
                setEditAppointment(null);
              }}
              style={{ margin: "30px 0px" }}
            >
              Add Appointment
            </AppButton>
          </Col>
        </Row>
      ) : null}

      {showAppointmentModal && (
        <AppointmentModel
          showAppointmentModal={showAppointmentModal}
          editAppointment={editAppointment}
          setShowAppointmentModal={setShowAppointmentModal}
          viewAppointmentsList={viewAppointmentsList}
          setAppointmentEditObj={setAppointmentEditObj}
          clinicCurrenciesList={clinicCurrenciesList}
          setViewAppointmentsList={setViewAppointmentsList}
        />
      )}
    </div>
  );
};

export default TreatmentProductsView;
