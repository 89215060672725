import { useState, useEffect, useRef } from "react";
import { Form, Input, Row, Col, Checkbox, message } from "antd";

import { Typography } from "antd";

import { connect } from "react-redux";
import { login } from "../../services/redux/actions/authActions";
import PasswordHandleModal from "../../components/shared/modals/passwordHandleModal";
import {
  iosStoreUrl,
  playStoreUrl,
  primaryColor,
  ScanImageUrl,
  ScanImageIOSUrl,
  loginPhone,
  LogInPageLogo,
  companyName,
  companyType,
  smilePrivacyUrl,
  smileTermsUrl,
} from "colors-config";
import axios from "axios";
import { Auth } from "aws-amplify";

import { useHistory } from "react-router-dom";

import "../../assets/styles/routes/login.scss";
import { AnchorTag } from "components/shared/AnchorTag/index";

import AppButton from "components/shared/AppButton/AppButton";
// import { ReactComponent as SgPhone } from "assets/images/SgPhone.svg";
import { ReactComponent as PlayStoreIcon } from "assets/images/PlayStoreIcon.svg";
import { ReactComponent as AppStoreIcon } from "assets/images/AppStoreIcon.svg";

const { Title } = Typography;

const formItemLayout = {
  labelCol: {
    xs: {
      span: 24,
      offset: 1,
    },
    sm: {
      span: 8,
      offset: 2,
    },
    lg: {
      span: 8,
      offset: 3,
    },
    xl: { span: 8, offset: 3 },
    xxl: { span: 8, offset: 3 },
  },
  wrapperCol: {
    xs: {
      span: 24,
      offset: 1,
    },
    sm: {
      span: 20,
      offset: 2,
    },
    lg: {
      span: 16,
      offset: 3,
    },
    xl: { span: 15, offset: 3 },
    xxl: { span: 14, offset: 3 },
  },
};
const tailFormItemLayout = {
  wrapperCol: {
    xs: {
      span: 24,
      offset: 0,
    },
    sm: {
      span: 20,
      offset: 2,
    },
    lg: {
      span: 16,
      offset: 3,
    },
    xl: { span: 15, offset: 3 },
    xxl: { span: 14, offset: 3 },
  },
};

function Login({ login, error }) {
  const [isRegistered, setIsRegistered] = useState(false);
  const [isSpinning, setIsSpinning] = useState(false);
  const [privacyPolicyUrl, setPrivacyPolicyUrl] = useState("");
  const [termsOfServiceUrl, setTermsOfServiceUrl] = useState("");
  const [showForgotPasswordModal, setShowForgotPasswordModal] = useState(false);
  const [userEmail, setUserEmail] = useState("");
  const containerRef = useRef();

  const [form] = Form.useForm();

  useEffect(() => {
    document.body.style = "background:white !important;";
    _getPolicyLinks();

    return () => { };
  }, []);

  useEffect(() => {
    if (error?.code === 'UserNotConfirmedException') {
      history.push(`/confirm-user?type=expired&email=${userEmail}`)
    }
  }, [error.code])

  const history = useHistory();

  const _getPolicyLinks = async () => {
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/public/getGenericTC`
      );
      let termsUrl = smileTermsUrl;
      let privacyUrl = smilePrivacyUrl;
      if (
        res?.data?.body?.privacy_policy?.url &&
        res?.data?.body?.terms_of_service?.url
      ) {
        // if (companyType !== 1) {
        termsUrl = res?.data?.body?.terms_of_service?.url;
        privacyUrl = res?.data?.body?.privacy_policy?.url;
        // }
      }
      setPrivacyPolicyUrl(privacyUrl);
      setTermsOfServiceUrl(termsUrl);
    } catch (error) {
      console.log(error);
    }
  };

  async function signIn(username, password) {
    login(username, password, history, setIsSpinning);
  }

  useEffect(() => {
    const remember = localStorage.getItem("remember");

    if (remember && remember === "true") {
      const email = localStorage.getItem("email");

      if (email) {
        form.setFieldsValue({
          email,
          remember: true,
        });
      }
    }
  }, []);

  const onFinish = (values) => {
    setIsRegistered(true);
    setIsSpinning(true);
    const { email: username, password, remember } = values;
    setUserEmail(username);
    if (remember) {
      localStorage.setItem("remember", remember);
      localStorage.setItem("email", username);
    }
    signIn(username, password);
  };

  const confirmForgotPassword = async (email) => {
    try {
      const res = await Auth.forgotPassword(email);
      history.push({
        pathname: "/forgot-password",
        state: { message: email },
      });
    } catch (error) {
      console.log(error);
      message.error(error.message);
    }
  };

  return (
    <div ref={containerRef} className="login-container">
      {showForgotPasswordModal && (
        <PasswordHandleModal
          title="Forgot Password"
          showModal={showForgotPasswordModal}
          onConfirmation={confirmForgotPassword}
          setShow={setShowForgotPasswordModal}
        ></PasswordHandleModal>
      )}
      <div className="login-phone">
        {/* <SgPhone /> */}
        <img src={loginPhone} alt="loginPhone" className={`${companyType===8?"brushbar-phone":""}`} />
        <loginPhone/>
      </div>
      <Row>
        <Col lg={12} style={{ textAlign: "left" }}>
          <div className="login-image-container">
            <div className="login-image-footer">
              <Row align="middle" justify="end">
                <Col>
                  <div style={{ marginRight: "10px" }}>
                    <p>Upgrade Your Practice with Our</p>
                    <b style={{ color: primaryColor }}> New Doctor App!</b>
                  </div>
                </Col>
                <Col>
                <div className="login-qr-container">
                    {/* First QR Section */}
                    <div className="login-qr">
                      <div className="store-icon">
                        <a href={playStoreUrl} target="_blank" rel="noreferrer">
                          <PlayStoreIcon />
                        </a>
                      </div>
                      <div className="qr-image">
                      <img src={ScanImageUrl} alt="App QR Code"  />
                      </div>
                     
                    </div>

                    {/* Second QR Section */}
                    <div className="login-qr">
                      <div className="store-icon">
                        <a href={iosStoreUrl} target="_blank" rel="noreferrer">
                          <AppStoreIcon />
                        </a>
                      </div>
                      <div className="qr-image">
                      <img src={ScanImageIOSUrl} alt="App QR Code"  />
                      </div>
                    </div>
                  </div>

                  {/* Description Section */}
                  <div className="login-desc">
                    <span>Scan the QR code to download or find it in your app store</span>
                  </div>
                </Col>
              </Row>
            </div>
            <img className="image" src={LogInPageLogo} alt="placeholder" />
          </div>
        </Col>
        <Col lg={12} md={24} sm={24} xs={24}>
          <div className="registration-form-container">
            <div className="register-form">
              <div className="login-title-container">
                <Title
                  level={2}
                  style={{
                    fontFamily: "'Poppins', sans-serif",
                    marginBottom: "0.1rem",
                  }}
                >
                  Login to{" "}
                  <span style={{ color: primaryColor }}>Clinician Portal</span>
                </Title>

                <p
                  style={{
                    fontSize: "14px",
                    fontWeight: "300",
                    fontFamily: "Mulish",
                  }}
                >
                  Let’s take the first step to start your journey with{" "}
                  {companyName}
                </p>
              </div>
              <Form
                className="registration-form"
                {...formItemLayout}
                form={form}
                layout={"vertical"}
                name="register"
                onFinish={onFinish}
                scrollToFirstError
                style={{ marginTop: "20px" }}
              >
                <Form.Item
                  name="email"
                  style={{ fontFamily: "Mulish" }}
                  rules={[
                    {
                      type: "email",
                      message: "Invalid email address",
                    },
                    {
                      //required: true,
                      message: "Please input your E-mail!",
                    },
                  ]}
                  label="Email ID"
                >
                  <Input size="large" placeholder="Email ID" />
                </Form.Item>

                <Form.Item
                  name="password"
                  label="Password"
                  style={{ fontFamily: "Mulish", marginBottom: "10px" }}
                  rules={[
                    {
                      //required: true,
                      message: "Please input your password!",
                    },
                  ]}
                  hasFeedback
                >
                  <Input.Password size="large" placeholder="Password" />
                </Form.Item>

                <Form.Item style={{ marginBottom: "0" }}>
                  <div
                    style={{
                      textAlign: "left",
                      float: "left",
                      fontFamily: "Mulish",
                    }}
                  >
                    <Form.Item name="remember" valuePropName="checked" noStyle>
                      <Checkbox>Remember Me</Checkbox>
                    </Form.Item>
                  </div>
                  <AnchorTag
                    style={{ fontFamily: "Mulish" }}
                    className="login-form-forgot"
                    onUrlClicked={() => setShowForgotPasswordModal(true)}
                    text="Forgot Password?"
                  />
                </Form.Item>

                <Form.Item {...tailFormItemLayout}>
                  <div className="btn-container">
                    {/* <Spin spinning={isSpinning}> */}
                    <AppButton
                      loading={isSpinning}
                      type="primary"
                      className="registration-button"
                      htmlType="submit"
                      block
                    >
                      Log in
                    </AppButton>
                    {/* </Spin> */}
                  </div>
                </Form.Item>
              </Form>

              <div>
                <Row>
                  <Col
                    xs={{
                      span: 24,
                      offset: 0,
                    }}
                    sm={{
                      span: 20,
                      offset: 2,
                    }}
                    lg={{
                      span: 16,
                      offset: 3,
                    }}
                    xl={{ span: 15, offset: 3 }}
                    xxl={{ span: 14, offset: 3 }}
                  >
                    <p
                      style={{
                        textAlign: "center",
                        color: "#1D1D1D",
                        fontSize: "12px",
                        fontFamily: "Mulish",
                        width: "100%",
                      }}
                    >
                      By clicking on ‘Login’ you agree to our{" "}
                      <AnchorTag
                        url={termsOfServiceUrl}
                        target="_blank"
                        rel="noreferrer"
                        text="Terms of Service"
                      />
                      &nbsp;and&nbsp;
                      <AnchorTag
                        url={privacyPolicyUrl}
                        target="_blank"
                        rel="noreferrer"
                        text="Privacy Policy"
                      />
                    </p>
                  </Col>
                </Row>
              </div>
            </div>
          </div>
        </Col>
      </Row>
    </div>
  );
}

const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
  error: state.error,
});

export default connect(mapStateToProps, { login })(Login);
