
import React, { useState, useEffect, useMemo } from "react";
import { Table, Tag, Input, Row, Col, Typography, Pagination, Tooltip, Dropdown, Menu, message, Spin } from "antd";
import { ArrowLeftOutlined } from '@ant-design/icons';
import moment from "moment";
import { Empty } from "antd";
import { ShowTotalNumber } from "components/shared/ShowTotalNumber/ShowTotalNumber";
import { columnTitleElement } from "utilities/commonMethod";
import { useHistory } from "react-router-dom";
import { getArchivePatientList } from "api/clinic";
import { connect } from "react-redux";
import FilterDataRangePicker from "components/shared/filterDataRangePicker";
import { displayDate } from "components/shared/Date/Date";

const { Title } = Typography;

const ArchivedPatients = ({ clinic }) => {
    const [paginationObj, setPaginationObj] = useState({});
    const [tableData, setTableData] = useState([]);
    const [patientsFilterData, setPatientsFilterData] = useState([]);
    const [totalRecords, setTotalRecords] = useState(0);
    const [paginationPage, setPaginationPage] = useState(1);
    const [paginationLimit, setPaginationLimit] = useState(50);
    const [isPatientTableSpinning, setIsPatientTableSpinning] = useState(false)
    const [startDateFilter, setStartDateFilter] = useState("");
    const [endDateFilter, setEndDateFilter] = useState("");

    useEffect(() => {
        if (clinic?.clinicId) {
            _getArchiveList(clinic?.clinicId);
        }
    }, [clinic.clinicId,startDateFilter, endDateFilter])

    useEffect(() => {
        const obj = {
            current: paginationPage,
            pageSize: paginationLimit,
            showSizeChanger: true,
            pageSizeOptions: [5, 10, 20, 50],
            total: totalRecords,
        };

        setPaginationObj(obj);
    }, [paginationPage, paginationLimit, totalRecords]);

    const handleTableChange = (pagination, filters, sorter) => {
        setPaginationPage(pagination.current);
        setPaginationLimit(pagination.pageSize);
    };


    const _getArchiveList = async (clinicId) => {
        setIsPatientTableSpinning(true);

        const params = {
            limit: 1000,
            page: 1,
            clinicId: clinicId,
            startDate: startDateFilter,
            endDate: endDateFilter,
        }

        try {

            const resData = await getArchivePatientList(params);
            const list = resData?.body?.data;
            const dataList = list.map((dataObj, index) => {
                return {
                    key: index,
                    dateCreated: dataObj.patientCreated
                        ? displayDate(dataObj.patientCreated)
                        : "Not Available",
                    patientDetails: {
                        patientID: dataObj.patient_id,
                        patientName: dataObj.patient_first_name + " " + dataObj.patient_last_name,
                    },
                    archiveDate: dataObj.archiveDate ? displayDate(dataObj.archiveDate) : "Not Available",
                };
            });
            console.log('dataaa', dataList)
            setTableData(dataList);
            setPatientsFilterData(dataList);
            setTotalRecords(dataList.length);
            setIsPatientTableSpinning(false);

        } catch (e) {
            console.log('testtewff', e)
            setIsPatientTableSpinning(false);
            message.error("Failed to load patients list");
        }
    }

    const handleDateFilter = (confirm, start = "", end = "") => {
        setStartDateFilter(start)
        setEndDateFilter(end)
        setPaginationPage(1);
        confirm({ closeDropdown: true });
      };

    const history = useHistory();
    const columns = [
        {
            width: 170,
            title: columnTitleElement("PATIENT DETAILS"),
            dataIndex: "patientDetails",
            key: "patientDetails",
            render: (data) => details(data?.patientName, data?.patientID),
            sorter: (a, b) => {
                // Convert clinicName to lowercase for case-insensitive comparison
                const nameA = a?.patientDetails?.patientName?.toLowerCase();
                const nameB = b?.patientDetails?.patientName?.toLowerCase();
        
                if (nameA < nameB) {
                  return -1;
                }
                if (nameA > nameB) {
                  return 1;
                }
                return 0;
            },
        },
        {
            width: 170,
            title: columnTitleElement("ARCHIVE DATE"),
            dataIndex: "archiveDate",
            key: "archiveDate",
            filtered: startDateFilter && endDateFilter,
            filterDropdown: ({ confirm, clearFilters }) => (
                <FilterDataRangePicker
                    clearFilters={clearFilters}
                    confirm={confirm}
                    handleDateFilter={handleDateFilter}
                    fromDate={startDateFilter}
                    endingDate={endDateFilter}
                />
            ),
        },
    ]
    const details = (name, id) => {
        return (
            <>
                <Row>
                    <p style={{ fontWeight: '600', marginBottom: '0', marginTop: '0' }}> {name}</p>
                </Row>
                <Row>
                    <p style={{ fontWeight: '300', marginBottom: '0', marginTop: '0' }}> {id}</p>
                </Row>
            </>
        );
    };
    let locale = {
        emptyText: (
            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={<span>No Patients to Show</span>} />
        ),
    };
    return (
        <div style={{ width: "100%" }}>
            <Row style={{ justifyContent: "space-between" }}>
                <Col style={{ textAlign: "start" }}>
                    <div className="headerListPatient">
                        <ArrowLeftOutlined onClick={() => { history.push("/clinic/patients") }} />
                        <Title style={{margin: 0}} level={3}>Archive Patients</Title>
                        {patientsFilterData && (
                            <ShowTotalNumber total={patientsFilterData?.length} className={"patientListLength"} />
                        )}
                    </div>
                </Col>
            </Row>
            <Spin spinning={isPatientTableSpinning}>
                <Table
                    scroll={{
                        y: 800,
                    }}
                    style={{ width: "100%", overflow: "hidden", marginTop: "0.5rem" }}
                    columns={columns}
                    dataSource={patientsFilterData}
                    pagination={paginationObj}
                    onChange={handleTableChange}
                    locale={locale}
                />
            </Spin>
        </div>
    );
}
const mapStateToProps = (state) => ({
    clinic: state.clinic,
});
export default connect(mapStateToProps)(ArchivedPatients);