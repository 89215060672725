import {  useEffect, useState } from "react";
import { Spin, Alert } from "antd";
import { useHistory } from "react-router-dom";
import { Redirect, Route } from "react-router";
import { connect,useSelector } from "react-redux";
import { logout } from "../services/redux/actions/authActions";
import { Mixpanel } from '../utilities/mixPanel';
import ReactGA from "react-ga4";
import LayoutComponent from "../components/private/layoutComponent";
import { useDispatch } from "react-redux";
import { checkLastActiveStatus } from "utilities/commonMethod";
import SentryRoute from "customHooks/useSentryRoute";

const PrivateRoute = (props) => {
  const dispatch = useDispatch();
  const [locationRun, setLocationRun] = useState(false)
  const { component: Component, isAuthenticated, isLoading, ...rest } = props;
  const history = useHistory();

  // useEffect(() => {
  //   if(props.clinic && props.clinic.clinicId && !props.clinic.isOnboarded){
  //     history.push("/clinic/profile")

  //   }
  // },[Component]);
  const state = useSelector(s=>s)

  // useEffect(()=>{

  //   if(state.clinic?.isFirstLogin && !locationRun){
  //     const isFirstLogin = state.clinic?.isFirstLogin;
  //     const onboardingStep = state.clinic?.onboardingStep;
  
  //     if (isFirstLogin) {
  //       if(onboardingStep){
  //         history.push("/onboarding?page=" + onboardingStep);

  //       }
  //     }
  //     setLocationRun(true);
  //   }
   
  // },[state.clinic])

  //Mix Panel
  // useEffect(()=>{
  //   // Mixpanel.identify(state.clinic.clinicId);
  //   Mixpanel.track(state.clinic.clinicName);
  //   Mixpanel.people.set(state.clinic.clinicName,{
  //     $clinicName: state.clinic.clinicName,
  //     $clinicEmail: state.clinic.clinicEmail,
  //   });
  // },[window.location.href])

  // Google Gtag
  useEffect(() => {
    if (state.clinic.clinicName && state.clinic.clinicId) {
      ReactGA.initialize(process.env.REACT_APP_G_TAG,{
        gaOptions: {
          name: state.clinic.clinicName,
          userId: state.clinic.clinicId
        }
      });
      ReactGA.send("pageview");
    }
    else{
      ReactGA.initialize(process.env.REACT_APP_G_TAG);
    }

  }, [state.clinic])


  const renderRoute = () => {
   
    let redirectCheck = false;
    if (isAuthenticated) {
      if(checkLastActiveStatus()){
        dispatch(logout());
        redirectCheck = true;
      }
      return (
        <LayoutComponent>
          {props.clinic.clinicId &&
            !props.clinic?.isOnboarded &&
            !props.clinic?.isFirstLogin && (
              <div style={{ marginBottom: "0.5rem" }}>
                <Alert
                  message="Please complete your profile"
                  type="info"
                />
              </div>
            )}

          <Component {...props} />
        </LayoutComponent>
      );
    }
    else {
      redirectCheck = true;
    }

    if(redirectCheck) {
      return (
        <Redirect
          to={{
            pathname: "/login",
            state: { from: props.location },
          }}
        />
      );
    }
  }
  
  return (
    <div>
      {isLoading && (
        <div>
          <Spin style={{ marginTop: "2rem" }}></Spin>
        </div>
      )}
      {!isLoading && (
        <SentryRoute
          {...rest}
          render={renderRoute}
        />
      )}
    </div>
  );
};

const mapStateToProps = ({ auth, clinic }) => ({
  isAuthenticated: auth.isAuthenticated,
  isLoading: auth.isLoading,
  clinic: clinic,
});

export default connect(mapStateToProps)(PrivateRoute);
