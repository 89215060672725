import { useState, useEffect, useContext } from "react";
import { Form, Modal, Button, Typography, Spin, message } from "antd";
import TreatmentProductForm from "../TreatmentProductForm/TreatmentProductForm";
import { useSelector, useDispatch } from "react-redux";
import {
  clinicIdSelector,
  getClinicProductTypesSelector,
  clinicPayments,
  getClinicProfileSelector
} from "services/redux/selectors/clinicSelectors";
import { addClinicProductAPI, updateClinicProductAPI } from "api/settingsApi";
import { getClinicProfile, updateClinicCommonProductTypeAction, updateClinicTreatmentProductsAction } from "services/redux/actions/clinicActions";
import { mapTreatmentInitialProduct, mapTreatmentProduct, updateProductList } from "utilities/settings";
import { PaymentTypes } from "components/shared/modals/PaymentSettings/constants";
import AppSettingsContext from "Context/useAppContext";
import { ExclamationCircleFilled } from "@ant-design/icons";
import { primaryColor } from "colors-config";
const { confirm } = Modal;

const { Title } = Typography;

const ProductModal = ({
  showProductModal,
  setShowProductModal,
  editTreatmentProduct,
  paymentList,
  clinicTreatmentProducts
}) => {
  const dispatch = useDispatch()
  const clinicProductsList = useSelector(getClinicProductTypesSelector);
  const clinicId = useSelector(clinicIdSelector);
  const [addProductForm] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const payments = useSelector(clinicPayments);
  const [productList, setProductList] = useState([]);
  const [freeProduct, setFreeProduct] = useState(false)
  const clinicProfile = useSelector(getClinicProfileSelector)

  const { setClinicConfig } = useContext(AppSettingsContext);

  useEffect(() => {
    let formData;
    if (editTreatmentProduct) {
       formData = mapTreatmentProduct(editTreatmentProduct)    
      const selectedProductType = clinicProductsList?.find(
        (item) => item.product_type === formData.productType
      );
      if (selectedProductType) {
        setProductList(selectedProductType?.productNamesList || []);
      }
      setFreeProduct(formData.free)
    } 
    addProductForm.setFieldsValue(formData);
  }, [editTreatmentProduct, addProductForm, clinicProductsList]);


useEffect(() => {
  if (!editTreatmentProduct) {
    // const payInClinicId = payments?.find((payment) => payment.type === PaymentTypes.IN_CLINIC)
    const activePayments = payments.filter((payment) => payment.default === 1)
    const  formData = mapTreatmentInitialProduct(activePayments);
    addProductForm.setFieldsValue(formData);
  }

}, [payments,addProductForm, editTreatmentProduct])


  const handleSubmit = () => {
    addProductForm.submit();
  };

  const handleAddResourceCancel = () => {
    if (addProductForm.isFieldsTouched()) {
      showConfirm();
    } else {
      setShowProductModal(false);
    }
  };


  const dispatcherFunction = (data) =>{
    const selectedProductTypeValue = clinicProductsList?.find(
      (item) => item.product_type_id === data.product_type_id
    );
    if (selectedProductTypeValue) {
      selectedProductTypeValue.productNamesList.push({
        product_name:  data.product_name,
        product_name_id: data.product_name_id
      })
    }
    const mappedClinicProductList = clinicProductsList.map((item) =>{
      if (item.product_type_id === data.product_type_id) {
       return selectedProductTypeValue 
      }
      return item
    })
    // console.log("mapped", mappedClinicProductList)
    dispatch(updateClinicCommonProductTypeAction(mappedClinicProductList))
  }
  const onProductSubmit = async (values) => {
     let storeData = [...clinicTreatmentProducts];     
     if (editTreatmentProduct) {
       storeData = [...clinicTreatmentProducts.filter(obj => obj.product_id != editTreatmentProduct?.product_id)] 
     }
     
    if (!values.free) {
      if (!values.productPrice || values.productPrice <= 0) {
        return message.error("Price should be greater than zero or mark free instead.");
      }
    }
   
    const selectedProductType = clinicProductsList.find(
      (item) => item.product_type === values.productType
    );
    let productTypeId = selectedProductType?.product_type_id;
    const selectedProductName = selectedProductType?.productNamesList.find(
      (item) => item.product_name === values.productName
    );
    let productNameId = selectedProductName?.product_name_id;
    const existingProduct = storeData.find((product) => product.product_name === values.productName && product.product_type === values.productType)
    if (existingProduct) {
      return message.error("Product already exist")
    }

    if (!productNameId) {
      productNameId = -1;
    }
    
    const { hidePatientPrice, discountPercentage, total, ...restValues} = values;
    const payload = {
      ...restValues,
      productTypeId,
      productNameId, 
      hidePatientPrice: hidePatientPrice ?? false,
      free: values.free ? true : false,
      productType: `${values.productType}`,
      discount: values.discount || 0,
      productDescription: values.productDescription || ""

    };
    try {
      setLoading(true);
   

      if (discountPercentage) {
        delete payload.discountPercentage; // Remove discountPercentage if it exists
      }
      
      if (editTreatmentProduct) {
        const productId = editTreatmentProduct?.product_id
        const { data } = await updateClinicProductAPI(payload,productId, clinicId);
      //  console.log("data.data", data.data)
       const res = data.data
       const updatedObj={
        productType: res.product_type,
        productPrice: res.price,
        productCurrencyId: res.product_currency_id,
        discount: res.discount,
        productDescription: res.description,
        free: res.free,
        paymentIds: res.paymentIds,
        productName : res.product_name
       }
       
      let finalStoreData = [...clinicTreatmentProducts];     
        const finalData = updateProductList(finalStoreData, productId, updatedObj)
        if (payload.productNameId === -1) {
          if (data) {
            dispatcherFunction(data.data)
          }
        }
        message.success("Product updated successfully");
        dispatch(updateClinicTreatmentProductsAction(finalData));
        setShowProductModal(false)  
      }
      else{
        const { data } = await addClinicProductAPI(payload, clinicId)
         if (payload.productNameId === -1) {
          if (data) {
            dispatcherFunction(data.data)
          }
        }
        message.success("Product added successfully");
        storeData.push({
          ...values,
          paymentIds: JSON.stringify(values.paymentIds),
          product_id: data?.data?.product_id,
          description: values.productDescription,
          product_name: values.productName,
          price: values.productPrice,
          total: values.productPrice - values.discount || 0,
          product_currency_id: Number(values.productCurrencyId),
          free: values?.free ? true : false
        })
        dispatch(updateClinicTreatmentProductsAction(storeData));
        dispatch(getClinicProfile())
        setClinicConfig((prev) => ({...prev,isProductAvailable : clinicProfile?.isProductAvailable}))
        setShowProductModal(false)
      }
    } catch (error) {
      console.error(error);
      message.error("Failed to add product");
    } finally {
      setLoading(false);
  
    }
  };



  const showConfirm = () => {
    confirm({
      title: "Close product form",
      icon: <ExclamationCircleFilled />,
      content: "Any unsaved changes will be discarded. Do you want to proceed?",
      okButtonProps: {
        style: {
          width: "7rem",
          background: primaryColor,
          borderColor: primaryColor,
          borderRadius: "30px",
          color: "#fff",
        },
      },
      cancelButtonProps: {
        style: {
          width: "7rem",
          borderRadius: "30px",
        },
      },
      onOk() {
        setShowProductModal(false);
      },
      onCancel() {
        // console.log('Cancel');
      },
    });
  };


  return (
    <div>
      <Modal
        className="addResourceModal"
        title={
          <div className="titleSpan">
            <Title level={5} style={{ margin: "0px" }}>
              {editTreatmentProduct
                ? "Edit Treatment/Product"
                : "Add Treatment/Product"}
            </Title>
          </div>
        }
        width={850}
        open={showProductModal}
        onCancel={handleAddResourceCancel}
        footer={[
          <Button
            key="cancel"
            onClick={handleAddResourceCancel}
            shape="round"
            style={{ width: "7rem" }}
          >
            Cancel
          </Button>,
          <Button
            key="submit"
            type="primary"
            shape="round"
            onClick={handleSubmit}
            loading={loading}
            style={{ width: "7rem" }}
          >
            {editTreatmentProduct ? "Update" : "Save"}
          </Button>,
        ]}
      >
        <Spin spinning={loading}>
          <TreatmentProductForm
            onProductSubmit={onProductSubmit}
            addProductForm={addProductForm}
            paymentList={paymentList}
            clinicProductsList={clinicProductsList}
            productList={productList}
            setProductList={setProductList}
            freeProduct={freeProduct}
            setFreeProduct={setFreeProduct}
          />
        </Spin>
      </Modal>
    </div>
  );
};

export default ProductModal;
