import { ReactComponent as EmptyTable } from "assets/images/EmptyTable.svg";
import { ReactComponent as EmptyTableSmilelign } from "assets/images/EmptyTableSmilelign.svg";
import { ReactComponent as EmptyTableIAS } from "assets/images/EmptyTableIAS.svg";
import { ReactComponent as EmptyTableBrush } from "assets/images/EmptyTableBrushbar.svg";
import { companyType } from "colors-config";
import './empty.scss'
const AppEmpty = ({ info }) => {
  return (
    <div className="empty-container">
      {companyType === 5 ?
        <EmptyTableSmilelign /> :
        companyType === 6 ? <EmptyTableIAS /> :
        companyType === 8 ? <EmptyTableBrush /> :
          <EmptyTable />
      }
      <h2>{info}</h2>
    </div>
  );
};

export default AppEmpty;
