import React, { useState, useEffect, useRef } from "react";
import { Select, Card, message, Row } from "antd";
import dayjs from "dayjs";
import { primaryColor } from "colors-config";
import { getPrescriptionByVersion, getPrescriptionAPI } from "api/prescription";
import { findMaxPrescriptionVersion } from "utilities/prescription";
import NewPrescriptionForm from "../../private/newPrescriptionForm/PrescriptionForm";
import { ReactComponent as DraftIcon } from "../../../assets/images/Draft.svg";
import { useReactToPrint, ReactToPrint } from "react-to-print";
import "../../../assets/styles/components/patient-journey/patientPrescriptionFormComponent.scss";
import ViewScanFilesComponent from "./viewScanFilesComponent";
import AppButton from "components/shared/AppButton/AppButton";
import { displayDate } from "components/shared/Date/Date";

const { Option } = Select;

const PatientPrescriptionFormComponent = ({
  productList,
  durationList,
  labList,
  formData,
  isEditableFromDB,
  setCanBeEdited,
  scanUrls,
  patientId,
  editMode,
  setEditMode,
  PRESCRIPTION_FORM_STATUS,
  formDataObjectRef,
  clinicId,
  setPrescriptionFormData,
  status,
  prescriptionForm,
  mapPrescriptionData,
  selectedCaseId,
  setIsPageLoading,
  saveToDraftService,
  teethChartDraftRef,
  formDraftRef,
  patientInfo,
  treatmentId,
  clinicalFiles,
  activeCaseId,
  getImg,
  dropdownValues,
  setStatusesState,
  updateTreatmentOrigin,
  treatmentOrigin,
  setScanUrls
}) => {
  const { SUBMITTED, PARTIAL } = PRESCRIPTION_FORM_STATUS;
  const disabled = editMode === SUBMITTED;
  const prescriptionPdfRef = useRef(null);
  const teethChartRef = useRef(null);
  const [loadingPdf, setLoadingPdf] = useState(false);
  const [selectedVersion, setSelectedVersion] = useState(null);
  const [isVersionSelected, setIsVersionSelected] = useState(false);
  const [clinicalFilesArray, setClinicalFilesArray] = useState([]);
  const [deleteScanUrls, setDeleteScanUrls] = useState([])
  const [isMaxVersion, setIsMaxVersion] = useState(false)
  const scansRef = useRef(null)

  const saveToDraft = () => {
    const currentRef = formDataObjectRef.current;
      let draftTeeth;
      if (teethChartDraftRef?.current?.length > 0) {
        const newMapped = teethChartDraftRef.current.map((item) => {
          return {
            ...item,
            addOns: item.addOns.map((addOn) => {
              return {
                ...addOn,
                tooth_no: addOn.toothNo,
                toothProcedure: addOn.procedure,
              };
            }),
          };
        });
        let flattenedData = newMapped?.flatMap((teeth) => [
          {
            ...teeth,
            tooth_no: teeth.toothNo,
            toothProcedure: teeth.procedure,
            addOns: [],
          },
          ...teeth.addOns,
        ]);
        draftTeeth = flattenedData;
        const flatCheck = flattenedData.filter(
          (item) => item.toothNo === null || item.toothNo === ""
        );
        if (flatCheck.length > 0) {
          return message.error("Selected tooth status is missing to Save Draft");
        }
      }
      if (currentRef?.touched) {
        setPrescriptionFormData((prev) => ({
          ...prev,
          ...formDataObjectRef.current,
          teethChart: draftTeeth,
        }));
  
        saveToDraftService({
          ...formDataObjectRef.current,
          teethChart: draftTeeth,
        });

      }
  };

  const handleBeforeUnload = async event => {
    const currentRef = formDataObjectRef.current;
    if (currentRef && currentRef.touched) {
      event.preventDefault();
      await saveToDraft();
      event.returnValue = "";
    }
  };

  useEffect(() => {
    const currentRef = formDataObjectRef.current;
    if (currentRef) {
      currentRef.touched = false;
    }
     window.addEventListener("beforeunload", handleBeforeUnload);
    return () => {
      saveToDraft();
      if (currentRef?.touched) {
        message.info("Saving as draft");
      }

      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);

  useEffect(() => {
    if (isEditableFromDB) {
      setCanBeEdited(true);
    }
  }, [isEditableFromDB]);


  const onEditClick = () => {
    setEditMode(PARTIAL);
  };

  const onVersionChange = async (value) => {
    if (!value) {
      return;
    }
    setIsVersionSelected(true);

    try {
      setIsPageLoading(true);
      const maxVersion = findMaxPrescriptionVersion(
        formData.prescriptionVersions
      );

      setIsMaxVersion(maxVersion === value)

      let prescription = null;

      if (maxVersion === value) {
        const response = await getPrescriptionAPI(patientId, selectedCaseId);
        prescription = response?.data?.prescription;
      } else {
        const response = await getPrescriptionByVersion(formData.id, value);
        prescription = {...response.data?.prescription?.prescription_data, ...response.data?.prescription?.scan_files_data};
      }

      if (!prescription) {
        return;
      }
      mapPrescriptionData(prescription);
      setPrescriptionFormData(prescription);
      setScanUrls(prescription?.patient_onboarding_images)
      setSelectedVersion(value);
      teethChartRef.current.setTeethData(prescription.teethChart);
    } catch (err) {
    } finally {
      setIsPageLoading(false);
    }
  };

  useEffect(() => {
    if (formData?.prescriptionVersions?.length > 0 && !isVersionSelected) {
      setSelectedVersion(
        findMaxPrescriptionVersion(formData?.prescriptionVersions)
      );
    }
  }, [formData]);

  const handleDownload = useReactToPrint({
    options: {
      unit: "in",
      format: [8.5, 11], // Letter size
      orientation: "portrait",
      margin: 1, // Set 1 inch margin on all sides
    },
    pageStyle: "@page { margin: 10mm; }",
    content: () => {
      document
        .querySelectorAll(".print-part-pdf")
        .forEach(function (container) {
          if (container) {
            container.style.display = "block";
          }
        });
      return prescriptionPdfRef.current;
    },
    onAfterPrint: () => {
      document
        .querySelectorAll(".print-part-pdf")
        .forEach(function (container) {
          if (container) {
            container.style.display = "none";
          }
        });
    },
  });

  return (
    <>
        <Row
        style={{
          marginBottom: "1rem",
          width:"100%",
        }}
      >
        <ViewScanFilesComponent
          clinicId={clinicId}
          clinicalFiles={clinicalFiles}
          scanUrls={scanUrls}
          patientId={patientId}
          treatmentOriginId={treatmentOrigin}
          activeCaseId={activeCaseId}
          getImg={getImg}
          status={status}
          dropdownValues={dropdownValues}
          setStatusesState={setStatusesState}
          updateTreatmentOrigin={updateTreatmentOrigin}
          setClinicalFilesArray={setClinicalFilesArray}
          setScanUrls={setScanUrls}
          prescriptionForm={prescriptionForm}
          formData={formData}
          setPrescriptionFormData={setPrescriptionFormData}
          clinicalFilesArray={clinicalFilesArray}
          setDeleteScanUrls={setDeleteScanUrls}
          isMaxVersion={isMaxVersion}
          scansRef={scansRef}
          />
      </Row>
      <Card
      className="prescriptionCard"
      style={{ width: "100%", background: "transparent", padding: "0px" }}
      title={
        <div
           style={{ textAlign: "start", display: "flex", alignItems: "center", flexWrap:"wrap" }}
        >
          Prescription
          <Select
            disabled={!selectedVersion}
            value={selectedVersion}
            onChange={onVersionChange}
            placeholder="No Previous Version"
            notFoundContent={<p>No Previous Version</p>}
            style={{ margin: "0 14px", width: "140px" }}
          >
            {formData?.prescriptionVersions?.map((version) => {
              return (
                <Option value={version.value} key={version.value}>
                  {version.label}
                </Option>
              );
            })}
          </Select>
          <span
            style={{ marginRight: "10px", textTransform: "capitalize" }}
            className="version-date"
          >
            Updated By: {formData?.updated_from}
          </span>
          <span className="version-date">
            {formData?.createdAt
              ? displayDate(formData?.createdAt)
              : displayDate(dayjs())}
          </span>
          {editMode === PARTIAL && (
            <AppButton icon={<DraftIcon />} shape="round" className="draft-btn">
              Saved as draft
            </AppButton>
          )}
        </div>
      }
      extra={
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
          }}
        >
          <div>
            {editMode === SUBMITTED && (
              <AppButton
                shape="round"
                type="primary"
                onClick={onEditClick}
              >
                Edit
              </AppButton>
            )}
          </div>

          <div
            onClick={() => handleDownload()}
            style={{
              color: primaryColor,
              cursor: "pointer",
              marginLeft: "15px",
            }}
          >
            Download
          </div>
        </div>
      }
    >
  
      
      <NewPrescriptionForm
        prescriptionPdfRef={prescriptionPdfRef}
        treatmentId={treatmentId}
        patientInfo={patientInfo}
        selectedVersion={selectedVersion}
        prescriptionForm={prescriptionForm}
        setPrescriptionFormData={setPrescriptionFormData}
        scanUrls={scanUrls}
        status={status}
        productList={productList}
        durationList={durationList}
        labList={labList}
        patientId={patientId}
        clinicId={clinicId}
        loadingPdf={loadingPdf}
        formDataObjectRef={formDataObjectRef}
        formData={formData}
        disabled={disabled}
        setEditMode={setEditMode}
        editMode={editMode}
        setSelectedVersion={setSelectedVersion}
        PRESCRIPTION_FORM_STATUS={PRESCRIPTION_FORM_STATUS}
        teethChartRef={teethChartRef}
        teethChartDraftRef={teethChartDraftRef}
        formDraftRef={formDraftRef}
        clinicalFilesArray={clinicalFilesArray}
        setClinicalFilesArray={setClinicalFilesArray}
        selectedCaseId={selectedCaseId}
        setScanUrls={setScanUrls}
        setDeleteScanUrls={setDeleteScanUrls}
        deleteScanUrls={deleteScanUrls}
        setIsPageLoading={setIsPageLoading}
        scansRef={scansRef}
      />
    </Card>
    </>
 
  );
};

export default PatientPrescriptionFormComponent;
