import { Row, Col } from "antd";
import AttachmentSessionList from "./AttachmentSessionList/AttachmentSessionList";
import { getAlignerNumberBackground } from "utilities/IPR";
import { showTeethName } from "components/shared/TeethChart/data";

const AttachmentInfo = ({
  alignersLabel,
  onAlignerLabelClicked,
  upperAttachments,
  lowerAttachments,
  chartValue
}) => {
  return (
    <div className="attachmentInfo-container">
      <Row
        gutter={[16, 16]}
        style={{
          marginTop: "15px",
          alignItems: "flex-end"
        }}
      >
        <Col lg={4} sm={24}></Col>
        <Col lg={6}><p style={{ textAlign: 'left'}} className="patient-question">Attachment</p></Col>
        <Col lg={9}></Col>
        <Col lg={5}><p style={{ textAlign: 'left'}} className="patient-question">Pressure Line</p></Col>
      </Row>
      {upperAttachments.map((attachment, i) => {
        return (
          <div key={i}>
            {attachment.alignerValues.map((attachmentValue, index) => {
              if (attachmentValue.value) {
                return (
                  <div key={index}>
                    <Row gutter={[16, 16]} align="middle">
                      <Col lg={4} span={4}>
                        <p
                          style={{
                            background: `${getAlignerNumberBackground(
                              attachment.aligner_count
                            )} `,
                          }}
                          className={`toothName`}
                        >
                          {chartValue === "fdi"
                            ? attachmentValue.teeth
                            : showTeethName(
                                attachmentValue.teeth,
                                chartValue,
                                "upper"
                              )}
                        </p>
                      </Col>
                      <Col lg={6} span={6}>
                        <p className="attachment-response">
                        {attachmentValue.attachmentType ? attachmentValue.attachmentType : '--'}
                        </p>
                      </Col>
                      <Col lg={9} span={9}>
                        <p className="attachment-response">
                          {attachmentValue.detail
                            ? attachmentValue?.detail
                            : "--"}
                        </p>
                      </Col>
                      <Col lg={5} span={5}>
                        <p style={{ textTransform:'capitalize'}} className="attachment-response">
                        {attachmentValue.pressureLine
                            ? attachmentValue?.pressureLine
                            : "No"}
                        </p>
                      </Col>
                    </Row>
                  </div>
                );
              } else {
                return <div key={index}></div>;
              }
            })}
          </div>
        );
      })}
      {lowerAttachments.map((attachment, i) => {
        return (
          <div key={i}>
            {attachment.alignerValues.map((attachmentValue, index) => {
              if (attachmentValue.value) {
                return (
                  <div key={index}>
                    <Row gutter={[16, 16]} align="middle">
                      <Col lg={4} span={4}>
                        <p
                          style={{
                            background: `${getAlignerNumberBackground(
                              attachment.aligner_count
                            )} `,
                          }}
                          className={`toothName`}
                        >
                          {chartValue === "fdi"
                            ? attachmentValue.teeth
                            : showTeethName(
                                attachmentValue.teeth,
                                chartValue,
                                "lower"
                              )}
                        </p>
                      </Col>
                      <Col lg={6} span={6}>
                        <p className="attachment-response">
                          {attachmentValue.attachmentType ? attachmentValue.attachmentType : '--'}
                        </p>
                      </Col>
                      <Col lg={9} span={9}>
                        <p className="attachment-response">
                          {attachmentValue.detail
                            ? attachmentValue?.detail
                            : "--"}
                        </p>
                      </Col>
                      <Col lg={5} span={5}>
                        <p style={{ textTransform:'capitalize'}} className="attachment-response">
                        {attachmentValue.pressureLine
                            ? attachmentValue?.pressureLine
                            : "No"}
                        </p>
                      </Col>
                    </Row>
                  </div>
                );
              } else {
                return <div key={index}></div>;
              }
            })}
          </div>
        );
      })}
    </div>
  );
};

export default AttachmentInfo;
