import { useState, useEffect, useMemo, useContext } from "react";
import PropTypes from "prop-types";
import {
  Row,
  Col,
  Typography,
  message,
  Select,
  Modal,
  DatePicker,
  Alert,

  Dropdown,
} from "antd";
import "../../../assets/styles/components/patient-journey/patientTreatmentDetailsComponent.scss";
import { ExclamationCircleFilled } from "@ant-design/icons";
import moment from "moment";
import { CheckCircleOutlined, DownOutlined } from "@ant-design/icons";
import { ReactComponent as ExpandIcon } from "../../../assets/images/expand_icon.svg";
import {
  setPatientTreatmentApproveAPI,
  startRevisionTreatmentApi,
  startTreatmentApi,
} from "api/patient";
import { dentistClinicApiTasks } from "models/dentistClinic";
import {
  finishClinicIntercomJourneyStatus,
  getRefinementData,
  updateClinicIntercomJourneyStatus,
} from "api/clinic";
import { companyType, primaryColor } from "colors-config";
import EndTreatment from "./endTreatment/index";
import {
  JOURNEY_STATUS,
  TAG_STATUS,
  TREATMENT_STATUS,
} from "../../../constants";
import IPRDetail from "components/IPRDetail/IPRDetail";
import ModifyPlan from "./PatientTreatmentCard/Modals/ModifyPlan";
import AppTag from "components/shared/Tag/Tag";
import ApproveSentToPatient from "./TreatmentSection/ApproveSentToPatient";
import ModificationRequestModal from "../ModificationRequestModal/ModificationRequestModal";
import TreatmentDetailsModal from "../patient-journey/endTreatment/treatmentDetailsModal";
import { sendMessage } from "api/chat";
import { getOrderBtnBackground } from "utilities/settings";
import {
  checkClinicIntercomTourStatus,
  updateClinicIntercomStatus,
} from "api/clinic";
import RefinementViewModal from "../patient-journey/endTreatment/refinementViewModal";
import StartRefinementModal from "./StartRefinementModal";
import { getOrderBtnText } from "utilities/commonMethod";
import TreatmentFrame from "../TreatmentFrame/TreatmentFrame";
import AppButton from "components/shared/AppButton/AppButton";
import {
  showOrderTourAfterSendToPatient,
  updateClinicFirstTimeStatusFinish,
} from "services/redux/actions/clinicActions";
import DoctorAppModel from "components/shared/DoctorAppModel/DoctorAppModel";
import { useDispatch, useSelector } from "react-redux";
import AppSettingsContext from "Context/useAppContext";
import { getDoctorAppInfoSelector } from "services/redux/selectors/clinicSelectors";
import { ReactComponent as BulbIcon } from "assets/images/BulbIcon.svg";
import mixpanel from "mixpanel-browser";
import dayjs from "dayjs";
import { displayDate } from "components/shared/Date/Date";



const { confirm } = Modal;

const { Title } = Typography;
const { Option } = Select;
function PatientTreatmentDetailsComponent({
  wholeTreatmentPlan,
  treatmentPlansState,
  confirmTreatmentStatus,
  treatmentPlans,
  labOrders,
  setShowLabOrderModal,
  patientInfo,
  patientId,
  getPatientInformation,
  paymentStatus,
  setPayCheckOrder,
  setDropdownLabTreatmentId,
  setOrderRetainerClick,
  status,
  getCurrentTreatmentStatus,
  alignerList,
  setOrderRefinementClick,
  setIsModalUpdateOrderVisible,
  setFromPatientJourneyButton,
  productList,
  clinic,
  treatmentProgressDet,
  getData,
  sendAppStage,
  setIsModalSendVisible,
  setSendOrderWithoutPatientApproval,
  setSharePatientMessage,
  setFromSendToPatientAppRegistration,
  isModalApproveSendPlanVisible,
  setIsModalApproveSendPlanVisible,
  setFromShareWithPatient,
  setPatientInfoState,
  fromShareWithPatient,
  setOrderType,
  setPhaseOrderData,
}) {
  const [treatment, setTreatment] = useState({});
  const dispatch = useDispatch();
  const [checkTreatment, setCheckTreatment] = useState([]);
  const [labOrder, setLabOrder] = useState({});
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isModifyPlanTouched, setIsModifyPlanTouched] = useState(false);
  const [isModalApprovePlanVisible, setIsModalApprovePlanVisible] =
    useState(false);
  const [approvePlanPopLoading, setApprovePlanPopLoading] = useState(false);
  const [refinementRequested, setRefinementRequested] = useState(false);
  const [showRefinementButton, setShowRefinementButton] = useState(false);
  const [viewStartRefinementModal, setViewStartRefinementModal] =
    useState(false);

  const [treatmentLabOrders, setTreatmentLabOrders] = useState([]);

  const [treatmentValue, setTreatmentValue] = useState(1);

  const [modifyPlanPopLoading, setModifyPlanPopLoading] = useState(false);
  const [isModalModifyPlanVisible, setIsModalModifyPlanVisible] =
    useState(false);
  const [modifyComment, setModifyComment] = useState("");
  const [productOrServiceCurrencyOptions, setProductOrServiceCurrencyOptions] =
    useState([]);
  const [modificationModalOpen, setModificationModalOpen] = useState(false);
  const [treatmentDetailModalOpen, setTreatmentDetailModalOpen] =
    useState(false);

  const [refinementData, setRefinementData] = useState();
  const [refinementViewModalOpen, setRefinementViewModalOpen] = useState(false);
  const [approvePlanTourRun, setApprovePlanTourRun] = useState(false);
  const [startTreatmentTourRun, setStartTreatmentTourRun] = useState(false);
  const [showScanModel, setShowScanModel] =useState(false); 
  const clinicDoctorAppInfoSelector = useSelector(getDoctorAppInfoSelector);
  const [selectedStartDate, setSelectedStartDate] = useState(dayjs()); 

  const { clinicConfig } = useContext(AppSettingsContext);

  useEffect(() => {
    if (Array.isArray(labOrders)) {
      const filterTreatmentOrders = labOrders.filter(
        obj => obj.othersOrder === 0 || !obj.othersOrder
      );
      setTreatmentLabOrders(filterTreatmentOrders);
    }
  }, [labOrders]);

  const getRefinementDetails = async patient_id => {
    if (patient_id) {
      const refinementDetailsRes = await getRefinementData(patient_id);
      if (
        refinementDetailsRes?.data?.data ||
        status.clinic_patient_journey_status_name ==
          JOURNEY_STATUS.TREATMENT_COMPLETED
      ) {
        setShowRefinementButton(false);
        if (
          status.clinic_patient_journey_status_name ==
          JOURNEY_STATUS.REFINEMENT_REQUESTED
        ) {
          setRefinementRequested(true);
        }
      } else {
        setShowRefinementButton(true);
      }
      setRefinementData(refinementDetailsRes.data.data);
    }
  };

  useEffect(() => {
    if (treatment?.patient_id) {
      getRefinementDetails(treatment.patient_id);
    }
  }, [treatment]);

  const parseCurrency = currencies => {
    const currencyArray = currencies.map(currency => {
      return currency;
    });
    return currencyArray;
  };

  useEffect(() => {
    async function fetchClinicOptions() {
      try {
        const res = await dentistClinicApiTasks.getAll(
          "clinic/getClinicListOfValues"
        );

        setProductOrServiceCurrencyOptions(
          parseCurrency(res.body.currencyNames)
        );
      } catch (error) {
        console.error(error);
      }
    }

    fetchClinicOptions();
  }, []);

  useEffect(() => {
    if (clinic && patientInfo) {
      if (
        !approvePlanTourRun &&
        clinic?.firstTimeOnboard &&
        patientInfo?.clinic_patient_journey_status_id === 6 &&
        clinic?.firstTimeOnboardStep == "APPROVE_PLAN"
      ) {
        updateClinicIntercomJourneyStatus(clinic?.clinicId, {
          firstTimeOnboardStep: "START_TREATMENT_PENDING",
        });
        setTimeout(() => {
          window.Intercom(
            "startTour",
            process.env.REACT_APP_INTERCOM_TOUR_APPROVE_PLAN
          );
        }, 3000);
        setApprovePlanTourRun(true);
      }

      // if (clinic?.firstTimeOnboard && clinic?.firstTimeOnboardStep == "PLACE_ORDER") {
      //   setTimeout(()=>{
      //     window.Intercom('startTour', "544844");
      //     updateClinicIntercomJourneyStatus(clinic?.clinicId, {
      //       firstTimeOnboardStep: "START_TREATMENT_PENDING"
      //     })
      //   },3000);
      // }

      if (
        !startTreatmentTourRun &&
        clinic?.firstTimeOnboard &&
        patientInfo?.clinic_patient_journey_status_id === 14 &&
        clinic?.firstTimeOnboardStep == "START_TREATMENT"
      ) {
        finishClinicIntercomJourneyStatus(clinic?.clinicId);
        dispatch(updateClinicFirstTimeStatusFinish());
        setTimeout(() => {
          window.Intercom(
            "startTour",
            process.env.REACT_APP_INTERCOM_TOUR_START_TREATMENT
          );
        }, 3000);
        setStartTreatmentTourRun(true);
      }
    }
  }, [clinic, patientInfo]);

  useEffect(() => {
    if (clinic?.intercomOrderTourAfterPatientSent) {
      startIntercomTour();
    }
  }, [clinic]);

  const startIntercomTour = async () => {
    if (
      process.env.REACT_APP_INTERCOM_TOUR_SEND_TO_PATIENT &&
      process.env.REACT_APP_INTERCOM_TOUR_SEND_TO_PATIENT !== ""
    ) {
      const clinicIntercomTourStats = await checkClinicIntercomTourStatus(
        clinic.clinicId
      );
      if (
        !clinicIntercomTourStats?.data?.data?.intercomData ||
        clinicIntercomTourStats?.data?.data?.intercomData?.sendToPatient == 1
      ) {

        setTimeout(async () => {
          window.Intercom(
            "startTour",
            process.env.REACT_APP_INTERCOM_TOUR_SEND_TO_PATIENT
          );
          dispatch(showOrderTourAfterSendToPatient(false));
          await updateClinicIntercomStatus(clinic.clinicId, {
            sendToPatient: 2,
          });
        }, 2000);
      }
    }
  };

  useEffect(() => {
    if (wholeTreatmentPlan?.length) {
      const defaultTreatment = dropdownApprovedTreatment(wholeTreatmentPlan);
      setTreatment(defaultTreatment);
      setDropdownLabTreatmentId(defaultTreatment?.lab_treatment_id);

      setTreatmentValue(defaultTreatment?.treatment_plan_version);
    } else setTreatment({});
  }, [wholeTreatmentPlan]);

  useEffect(() => {
    if (treatmentLabOrders.length) {
      setLabOrder(treatmentLabOrders[0]);
    }
  }, [treatmentLabOrders]);

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const handleApprovePlanCancel = () => {
    setIsModalApprovePlanVisible(false);
  };
  const handleApproveSendPlanCancel = () => {
    setIsModalApproveSendPlanVisible(false);
    setFromShareWithPatient(false);
  };

  const showConfirm = () => {
    confirm({
      title: "Close Modification form",
      icon: <ExclamationCircleFilled />,
      content: "Any unsaved changes will be discarded. Do you want to proceed?",
      okButtonProps: {
        style: {
          width: "7rem",
          background: primaryColor,
          borderColor: primaryColor,
          color: "#fff",
        },
      },
      cancelButtonProps: {
        style: {
          width: "7rem",
        },
      },
      onOk() {
        setIsModalModifyPlanVisible(false);
        setModifyComment("");
        setIsModifyPlanTouched(false);
      },
      onCancel() {
        // console.log('Cancel');
      },
    });
  };
  const handleModifyPlanCancel = () => {
    if (isModifyPlanTouched) {
      showConfirm();
    } else {
      setIsModalModifyPlanVisible(false);
      setModifyComment("");
      setIsModifyPlanTouched(false);
    }
  };

  const approvePlanOk = async () => {
    try {
      setApprovePlanPopLoading(true);
      const inputData = {
        patientId: treatment.patient_id,
        treatmentSetupId: treatment.lab_treatment_id,
        caseId: treatment.case_id,
        status: true,
      };
      await setPatientTreatmentApproveAPI(inputData);
      setDropdownLabTreatmentId(treatment.lab_treatment_id);
      message.success("Patient Treatment has been approved.");
      treatmentPlans();
      getPatientInformation();
    } catch (error) {
      console.log("error", error);
      message.success("Error occurred!");
    } finally {
      setApprovePlanPopLoading(false);
      handleApprovePlanCancel();
    }
  };
  // console.log(treatment);

  const isPhasingOrder = () => {
    if (treatment.phasingOption === "no") {
      return false;
    }
    if (treatment.phasingOption === "yes" && treatment.phasingList.length) {
      const { phasingList = [] } = treatment;
      let countOrderedPhase = 0;
      phasingList.forEach(phase => {
        if (phase.patient_order_id) {
          countOrderedPhase = countOrderedPhase + 1;
        }
      });

      return Boolean(
        countOrderedPhase && countOrderedPhase < phasingList.length
      );
    }
  };
  const getPhaseOrderNumber = () => {
    let phaseNo = treatment?.phasingList?.length;

    treatment?.phasingList?.forEach(phase => {
      if (!phase?.patient_order_id) {
        if (phase.phaseNo < phaseNo) {
          phaseNo = phase.phaseNo;
        }
      }
    });

    return phaseNo
  };

  const modifyPlanOk = async () => {
    try {
      setModifyPlanPopLoading(true);
      await confirmTreatmentStatus(
        treatment?.lab_treatment_id,
        "rejected",
        modifyComment
      );

      if (companyType === 1) {
        sendMessage({
          meta: {
            sender_id: clinic.clinicId,
            receiver_id: treatment.lab_id,
            module: "clinic",
            patient_id: patientId,
          },
          message: `
          <p><strong>Modification Request</strong></p>
          <p>${modifyComment}</p>
          `,
        });
      }
      
      if (
        clinicDoctorAppInfoSelector &&
        !clinicDoctorAppInfoSelector.appInstalled && // Modal opens only if app is NOT installed
        !clinicDoctorAppInfoSelector.skipApp // Modal opens only if skipApp is NOT true
      ) {
        setShowScanModel(true);
      }
    } catch (err) {
      console.log("err", err);
    } finally {
      setModifyPlanPopLoading(false);
      setIsModalModifyPlanVisible(false);
    }
  };

  const startTreatment = async () => {
    const findLabOrder = treatmentLabOrders.find(
      obj => obj.lab_treatment_id == treatment.lab_treatment_id && obj.patient_order_id
    );
    let patientOrderId = labOrder.patient_order_id;

    if (findLabOrder) {
      patientOrderId = findLabOrder.patient_order_id;
    }
    let apiPromise;
    if (treatment.revision_plan_version) {
      apiPromise = startRevisionTreatmentApi({
        order_id: patientOrderId,
        treatmentId: treatment.lab_treatment_id,
      });
    } else {
      apiPromise = startTreatmentApi({
        order_id: patientOrderId,
        start_treatment_date: dayjs(selectedStartDate).format("YYYY-MM-DD"),
        createdFrom: "clinic"
      });
    }

    apiPromise.then(res => {
      message.success("Patient Treatment has been started.");
      mixpanel.people.set({
        started_treatment: true,
      });

      setIsModalVisible(false);
      getData();
    });
  };

  useMemo(() => {
    setCheckTreatment(
      wholeTreatmentPlan?.map(i => ({
        ...i,
        label: i.revision_plan_version
          ? `Refinement Plan Version ${i.revision_plan_version}.0`
          : `Treatment Plan Version ${i.treatment_plan_version}.0`,
        value: i.treatment_plan_version,
      }))
    );
  }, [wholeTreatmentPlan]);

  const dropdownApprovedTreatment = wholeTreatmentPlan => {
    const findApprovedTreatment = wholeTreatmentPlan.find(obj => obj.approved);
    return findApprovedTreatment
      ? findApprovedTreatment
      : wholeTreatmentPlan[0];
  };

  const checkPatientLabOrder = treatment => {
    const findLabOrder = treatmentLabOrders.find(
      obj => obj.lab_treatment_id == treatment.lab_treatment_id
    );
    return !findLabOrder;
  };

  const onOrderWithoutPatientClick = () => {
    setFromPatientJourneyButton(true);
    setSendOrderWithoutPatientApproval(true);
    if (
      !paymentStatus ||
      paymentStatus == "-" ||
      paymentStatus == "Awaiting Payment"
    ) {
      console.log("1111111")
      if (clinicConfig?.receiveAppPayment) {
        setIsModalUpdateOrderVisible(true);
      } else {
        console.log("222222")
        setShowLabOrderModal(true);
      }
    } else {
      // setPayCheckOrder(true); // if need to place order on patient behalf
      console.log("33333")
      setShowLabOrderModal(true);
      if (treatment.revision_plan_version) {
        setOrderRefinementClick(true);
      }
    }
  };

  const onSendPatientApprovalClick = () => {
    //

    if (sendAppStage == 0) {
      setIsModalSendVisible(true);
      setSharePatientMessage(true);
      setFromSendToPatientAppRegistration(true);
    } else {
      setIsModalApproveSendPlanVisible(true);
    }
  };

  const items = [
    {
      key: "1",
      label: "Approve without sharing with patient",
      onClick: onOrderWithoutPatientClick,
    },
    {
      key: "2",
      label: "Send to patient for approval",
      onClick: onSendPatientApprovalClick,
    },
  ];
  const statusMessageComponent = () => {
    if (treatment?.approval_status === TREATMENT_STATUS.REJECTED) {
      return (
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            width: "100%",
            textAlign: "right",
          }}
          className="treatment-actions-buttons"
        >
          {wholeTreatmentPlan.length > 1 ? (
            <AppButton
              shape="round"
              className="app-button-class"
              type="primary"
              onClick={() => {
                setIsModalApprovePlanVisible(true);
              }}
              style={{ marginRight: "6px" }}
            >
              Select plan
            </AppButton>
          ) : (
            ""
          )}
          <AppButton
            shape="round"
            type="default"
            onClick={() => setModificationModalOpen(true)}
            style={{ height: "40px" }}
          >
            View Modification Request
          </AppButton>
          <div style={{ display: "flex" }}>
            <AppButton
              shape="round"
              type="default"
              onClick={() => setTreatmentDetailModalOpen(true)}
              style={{ height: "40px" }}
            >
              Treatment Details
            </AppButton>
            <AppTag
              color={TAG_STATUS.ERROR}
              text={JOURNEY_STATUS.MODIFICATION_REQUESTED + ""}
              customStyle={{ height: "40px", marginLeft: "10px" }}
            />
          </div>
        </div>
      );
    } else if (treatment?.approval_status === TREATMENT_STATUS.PENDING) {
      return (
        <div
          style={{
            display: "flex",
            width: "100%",
            justifyContent: "space-between",
          }}
        >
          {refinementData && (
            <AppButton
              // className="app-button-class"
              shape="round"
              type="default"
              onClick={() => setRefinementViewModalOpen(true)}
            >
              View Refinement details
            </AppButton>
          )}
          <div>
            {!treatmentLabOrders?.length ||
            (treatment.revision_plan_version &&
              checkPatientLabOrder(treatment)) ? (
              <AppButton
                shape="round"
                onClick={() => setIsModalModifyPlanVisible(true)}
              >
                Modify Plan
              </AppButton>
            ) : (
              ""
            )}
            {!treatmentLabOrders?.length ||
            (treatment.revision_plan_version &&
              checkPatientLabOrder(treatment)) ? (
              <Dropdown
                menu={{
                  items,
                }}
              >
                <AppButton
                  shape="round"
                  style={{
                    marginLeft: "5px",
                    background: getOrderBtnBackground(),
                  }}
                  type="primary"
                >
                  {getOrderBtnText(refinementData)} <DownOutlined />
                </AppButton>
              </Dropdown>
            ) : (
              ""
            )}
          </div>
        </div>
      );
    } else if (treatment?.approval_status === TREATMENT_STATUS.APPROVED) {
      return (
        <div style={{ display: "flex", width: "100%" }}>
          <EndTreatment
            setViewStartRefinementModal={setViewStartRefinementModal}
            setRefinementViewModalOpen={setRefinementViewModalOpen}
            refinementViewModalOpen={refinementViewModalOpen}
            getRefinementDetails={getRefinementDetails}
            refinementData={refinementData}
            setRefinementData={setRefinementData}
            showRefinementButton={showRefinementButton}
            refinementRequested={refinementRequested}
            setRefinementRequested={setRefinementRequested}
            treatment={treatment}
            patientInfo={patientInfo}
            setShowLabOrderModal={setShowLabOrderModal}
            setOrderRetainerClick={setOrderRetainerClick}
            status={status}
            getCurrentTreatmentStatus={getCurrentTreatmentStatus}
            alignerList={alignerList}
            getPatientInformation={getPatientInformation}
            setIsModalModifyPlanVisible={setIsModalModifyPlanVisible}
            labOrders={treatmentLabOrders}
            setTreatment={setTreatment}
            setIsModalVisible={setIsModalVisible}
            labOrder={labOrder}
            setIsModalApprovePlanVisible={setIsModalApprovePlanVisible}
            setIsModalApproveSendPlanVisible={setIsModalApproveSendPlanVisible}
            sendAppStage={sendAppStage}
            setIsModalSendVisible={setIsModalSendVisible}
            setSharePatientMessage={setSharePatientMessage}
            treatmentPlans={treatmentPlans}
            setTreatmentDetailModalOpen={setTreatmentDetailModalOpen}
            setFromShareWithPatient={setFromShareWithPatient}
            setPatientInfoState={setPatientInfoState}
            setShowRefinementButton={setShowRefinementButton}
            setFromPatientJourneyButton={setFromPatientJourneyButton}
            setOrderType={setOrderType}
            isPhasingOrder={isPhasingOrder}
            getPhaseOrderNumber={getPhaseOrderNumber}
            setPhaseOrderData={setPhaseOrderData}
            orderButtonRender={
   
           (treatment.revision_plan_version &&
                  checkPatientLabOrder(treatment) &&
                  treatment?.approved) ||
                (checkPatientLabOrder(treatment) &&
                  !treatmentLabOrders?.length &&
                  treatment?.approved) ? (
                <AppButton
                  type="primary"
                  shape="round"
                  className="app-button-class"
                  onClick={() => {
                    setFromPatientJourneyButton(true);
                    if (
                      (!paymentStatus ||
                        paymentStatus === "-" ||
                        paymentStatus === "Awaiting Payment") &&
                      clinicConfig?.receiveAppPayment
                    ) {
                      setIsModalUpdateOrderVisible(true);
                    } else {
                      setPayCheckOrder(true);
                      setShowLabOrderModal(true);
                      if (treatment.revision_plan_version) {
                        setOrderRefinementClick(true);
                      }
                    }
                  }}
                >
                  Order
                </AppButton>
              ) : (
                ""
              )
            }
          />
        </div>
      );
    } else {
      return "";
    }
  };

  const handleChange = value => {
    setTreatment(
      wholeTreatmentPlan.filter(i => i.treatment_plan_version === value)[0]
    );
    setDropdownLabTreatmentId(
      wholeTreatmentPlan.filter(i => i.treatment_plan_version === value)[0]
        ?.lab_treatment_id
    );
    setTreatmentValue(value);
  };

  const handleScanOk= () =>{
    setShowScanModel(false)
   }
   const onScanCancel= () =>{
     setShowScanModel(false)
   }
   const treatmentDateChangeHandler = (date, dateString) => {
    // console.log("Selected Date:", dateString);
    setSelectedStartDate(date); 
  };

   
  const disableStartDates = (current) => {
    const shippedDate = labOrder?.aligner_shipped_date ? moment(labOrder.aligner_shipped_date) : null;
    const today = moment().endOf("day"); 
    if (shippedDate) {
      return current.isBefore(shippedDate, "day") || current.isAfter(today, "day");
    }
    return current.isAfter(today, "day"); 
  };

  return (
    <div
      style={{
        width: "100%",
        height: "100%",
        minHeight: "53.2rem",
        boxShadow: "0px 10px 16px rgba(0, 0, 0, 0.02)",
        borderRadius: "8px",
        backgroundColor: "white",
        paddingBottom: "1rem",
      }}
    >
      {wholeTreatmentPlan?.length > 0 && checkTreatment?.length > 0 ? (
        <div>
          <div className="treatment-dropdown-container">
            <Row
              justify="space-between"
              style={{
                flexDirection: "row",
                alignItems: "center",
                textAlign: "left",
                padding: "8px 24px",
              }}
            >
              <Col>
                <Title level={5} style={{ margin: "unset" }}>
                  {checkTreatment.length > 1 ? (
                    <Select
                      size="large"
                      value={treatmentValue}
                      onChange={value => {
                        handleChange(value);
                      }}
                    >
                      {checkTreatment.map((item, index) => {
                        return (
                          <Option
                            className="treatment-selectbox-multi"
                            key={index}
                            value={item.value}
                          >
                            {item.label}{" "}
                            {item.approved ? <CheckCircleOutlined /> : ""}
                          </Option>
                        );
                      })}
                    </Select>
                  ) : (
                    <span>{`Treatment Plan Version ${treatment?.treatment_plan_version}.0`}</span>
                  )}
                  {/* <Tooltip title="Open in another tab">
                    <ExpandIcon
                      style={{
                        width: "18px",
                        height: "auto",
                        marginLeft: "5px",
                        cursor: "pointer",
                        marginBottom: "-3px",
                        fill: primaryColor,
                      }}
                      onClick={() => {
                        window.open(treatment?.treatment_plan_link);
                      }}
                    />
                  </Tooltip> */}
                </Title>
              </Col>
              <Col>
                <div
                  style={{ display: "flex", alignItems: "center", gap: "10px" }}
                >
                  <div style={{ display: "block" }}>
                    {displayDate(treatment?.date_created)}
                  </div>
                </div>
              </Col>
            </Row>
          </div>
          <Row
            justify="space-between"
            style={{
              borderBottom: "1px solid lightgray",
              flexDirection: "row",
              alignItems: "center",
              textAlign: "left",
              padding: "8px 24px",
            }}
          >
            <div
              style={{
                display: "flex",
                marginLeft: "auto",
                width: "100%",
              }}
              className="treatment-actions-buttons"
            >
              {statusMessageComponent()}
            </div>
          </Row>
          <TreatmentFrame treatment={treatment} />
          <IPRDetail
            treatment={treatment}
            treatmentPlansState={treatmentPlansState}
            status={status}
            clinic={clinic}
            patientInfo={patientInfo}
          />
        </div>
      ) : (
        <>
          {!wholeTreatmentPlan?.length && (
            <div>
              <Row
                justify="space-between"
                style={{
                  borderBottom: "1px solid #f0f0f0",
                  height: "3.65rem",
                  textAlign: "left",
                  padding: "1rem",
                }}
              >
                <Col>
                  <Title style={{ marginTop: 0 }} level={5}>
                    {" "}
                    Treatment Plan
                  </Title>
                </Col>
                <Col style={{ marginTop: "-0.5rem" }}>
                  {statusMessageComponent()}
                </Col>
              </Row>
              <Row className="empty-plan-component ">
                No Treatment Plan has been added yet.
              </Row>
            </div>
          )}
        </>
      )}

      {isModalVisible && (
        <Modal
          title="Start Aligner Treatment"
          okText="Confirm"
          cancelText="Not Yet"
          visible={isModalVisible}
          onOk={startTreatment}
          onCancel={handleCancel}
          okButtonProps={{
            style: { width: "7rem", borderRadius: "30px" },
          }}
          cancelButtonProps={{ style: { width: "7rem", borderRadius: "30px" } }}
        >
         {companyType === 8 ? (
            <>
              <label style={{ color: "#798C96", fontWeight: "600" }}>
                Start Date
              </label>
              <DatePicker
                size="large"
                disabledDate={disableStartDates}
                style={{ width: "100%" }}
                defaultValue={selectedStartDate}
                onChange={treatmentDateChangeHandler}
                format="DD-MMM-YYYY"
              />

              <Alert
                className="scan-view-info"
                message="Update the date if the treatment began before today."
                type="info"
                showIcon
                icon={<BulbIcon />}
              />
            </>
          ) : (
            <p>Are you sure you want to start?</p>
          )}
        </Modal>
      )}

      {isModalApprovePlanVisible && (
        <Modal
          title="Select plan & Send"
          confirmLoading={approvePlanPopLoading}
          okText="Confirm"
          cancelText="Cancel"
          visible={isModalApprovePlanVisible}
          onOk={approvePlanOk}
          onCancel={handleApprovePlanCancel}
          okButtonProps={{
            style: { width: "7rem", borderRadius: "30px" },
          }}
          cancelButtonProps={{ style: { width: "7rem", borderRadius: "30px" } }}
        >
          <p>Do you want to select this plan and send to patient?</p>
        </Modal>
      )}

      {isModalApproveSendPlanVisible && (
        <ApproveSentToPatient
          isModalApproveSendPlanVisible={isModalApproveSendPlanVisible}
          handleApproveSendPlanCancel={handleApproveSendPlanCancel}
          treatment={treatment}
          productList={productList}
          treatmentProgressDet={treatmentProgressDet}
          productOrServiceCurrencyOptions={productOrServiceCurrencyOptions}
          setIsModalApproveSendPlanVisible={setIsModalApproveSendPlanVisible}
          confirmTreatmentStatus={confirmTreatmentStatus}
          patientId={patientId}
          clinic={clinic}
          wholeTreatmentPlan={wholeTreatmentPlan}
          fromShareWithPatient={fromShareWithPatient}
          setFromShareWithPatient={setFromShareWithPatient}
          setSendOrderWithoutPatientApproval={
            setSendOrderWithoutPatientApproval
          }
        />
      )}

      <ModifyPlan
        modifyComment={modifyComment}
        setModifyComment={setModifyComment}
        modifyPlanPopLoading={modifyPlanPopLoading}
        modifyPlanOk={modifyPlanOk}
        handleModifyPlanCancel={handleModifyPlanCancel}
        isModalModifyPlanVisible={isModalModifyPlanVisible}
        setIsModifyPlanTouched={setIsModifyPlanTouched}
      />
      {modificationModalOpen && (
        <ModificationRequestModal
          modificationModalOpen={modificationModalOpen}
          setModificationModalOpen={setModificationModalOpen}
          modifyComment={treatment?.modifyComment}
        />
      )}
      {treatmentDetailModalOpen && (
        <TreatmentDetailsModal
          treatmentDetailModalOpen={treatmentDetailModalOpen}
          setTreatmentDetailModalOpen={setTreatmentDetailModalOpen}
          treatment={treatment}
          setTreatment={setTreatment}
          patientInfo={patientInfo}
        />
      )}

      {refinementViewModalOpen && (
        <RefinementViewModal
          refinementModalOpen={refinementViewModalOpen}
          setRefinementModalOpen={setRefinementViewModalOpen}
          treatment={treatment}
          refinementData={refinementData}
        />
      )}

      {viewStartRefinementModal && (
        <StartRefinementModal
          alignerList={alignerList}
          labOrders={treatmentLabOrders}
          labOrder={labOrder}
          treatment={treatment}
          viewStartRefinementModal={viewStartRefinementModal}
          setViewStartRefinementModal={setViewStartRefinementModal}
          getData={getData}
        />
      )}

      {showScanModel && (
        <DoctorAppModel
          isModalVisible={showScanModel}
          handleOk={handleScanOk}
          handleCancel={onScanCancel}
          doNotShow
        />
      )}
    </div>
  );
}

PatientTreatmentDetailsComponent.propTypes = {
  setPhaseOrderData: PropTypes.func.isRequired, // set order id as string in it to use in the phase orders
};

export default PatientTreatmentDetailsComponent;
