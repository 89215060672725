import React from "react";
import { Card, Row, Col, Button, Checkbox } from "antd";
import { ReactComponent as SessionIcon } from "../../assets/images/sessionIcon.svg";
import { ReactComponent as AttachmentSessionIcon } from "../../assets/images/attachment-icon.svg";
import AppButton from "components/shared/AppButton/AppButton";
import { displayDate } from "components/shared/Date/Date";
const IPRCard = ({
  count,
  subTitle,
  checked,
  setShowPopupAddAppointment,
  setIprSelected,
  iprData,
  onCompleteClick,
  showAlignerOptions
}) => {
  function getOrdinal(number) {
    let num = number
    if (typeof number !== 'number') {
      num = +number
    }

    const lastDigit = number % 10;
    const secondLastDigit = Math.floor((number % 100) / 10);

    if (lastDigit === 1 && secondLastDigit !== 1) {
      return `${num}st`;
    } else if (lastDigit === 2 && secondLastDigit !== 1) {
      return `${num}nd`;
    } else if (lastDigit === 3 && secondLastDigit !== 1) {
      return `${num}rd`;
    } else {
      return `${num}th`;
    }
  }
  const getSessionTitle = () => {
    const iprCount = getOrdinal(count);
    if (iprData.type == "BOTH") {
      return `${iprCount} IPR + ${iprCount} Attachment Session`;
    }
    else if (iprData.type == "IPR") {
      return `${iprCount} IPR Session`;
    }
    else if (iprData.type == "ATTACHMENT") {
      return `${iprCount} Attachment Session`;
    }
    else return "";
  }
  return (
    <Card hoverable className="ipr-session-card">
      <Row justify="end">
        <Col lg={24}>
          <h1>{getSessionTitle()}</h1>
        </Col>
        <Col lg={12}>

          <h2>{subTitle}</h2>
          {showAlignerOptions ?
            <AppButton onClick={() => {
              setShowPopupAddAppointment(true);
              setIprSelected(true);
            }} type="primary" shape="round">Book Appointment</AppButton> : null
          }

          <div style={{ marginTop: "10px" }}>
            <Checkbox
              checked={checked}
              onClick={() => {
                if (showAlignerOptions) {
                  if (!checked) {
                    onCompleteClick(iprData);
                  }
                }
              }}
            >Completed</Checkbox>
          </div>

        </Col>
        <Col lg={12}>
          <div className="session-icon">
            {iprData.type != "IPR" ?
              <AttachmentSessionIcon />
              :
              <SessionIcon />
            }
          </div>

        </Col>
      </Row>
    </Card>

  );
};

export default IPRCard;
