import { useState, useEffect, useImperativeHandle, forwardRef } from "react";
import { Select, Divider, Collapse, Form, message } from "antd";
import TeethInfo from "./TeethInfo";
import { ReactComponent as DownArrow } from "../../../assets/images/DownArrow.svg";

import Teeths from "./Teeths/Teeths";
import { FDI_CHART } from "./data";

import "./teethChart.scss";
import { updateClinicTeethChartStructure } from "api/prescription";
import { primaryColor } from "colors-config";

const { Option } = Select;

const TeethChart = forwardRef(
  (
    {
      chartValue,
      setChartValue,
      country,
      disabled,
      teethChartData,
      clinicId,
      teethStructureValue,
      teethChartDraftRef,
    },
    ref
  ) => {
    const [toothStatuses, setToothStatuses] = useState([]);
    const [teethStructure, setTeethStructure] = useState(FDI_CHART);

    const [teethStructureLoading, setTeethStructureLoading] = useState(false);

    useImperativeHandle(ref, () => {
      return {
        setTeethData(data) {
          setToothStatuses(data?.length ? data : []);
        },
        toothStatuses,
      };
    });

    useEffect(() => {
      if (teethStructureValue) {
        // console.log("teethStructureValue",teethStructureValue);
        setChartValue(teethStructureValue);
      }
    }, [teethStructureValue]);

    useEffect(() => {
      // console.log(teethChartData)
      setToothStatuses(teethChartData);
    }, [teethChartData]);

    const handleStructureChange = async (value) => {
      try {
        setTeethStructureLoading(true);
        await updateClinicTeethChartStructure(clinicId, {
          teethStructure: value,
        });
        setChartValue(value);
      } catch (error) {
        message.error("Error occured in updating");
      } finally {
        setTeethStructureLoading(false);
      }
    };

    useEffect(() => {
      if (
        // chartValue === "palmer" ||
        country === "USA" ||
        country === "Canada " ||
        country === "Jamaica"
      ) {
        setChartValue("palmer");
      }
    }, [country, setChartValue]);

    const header = (
      <>
        <div className="chart-header">
          <div style={{ display: "flex", alignItems: "center" }}>
            <h1>Teeth Chart</h1>
          </div>
          <Select
            placeholder="Select option"
            className="header-select"
            onChange={handleStructureChange}
            value={chartValue}
            loading={teethStructureLoading}
          >
            <Option value="fdi">FDI</Option>
            <Option value="universal">Universal</Option>
            <Option value="palmer">Palmer</Option>
          </Select>
        </div>
        <Divider />
      </>
    );

    const getItems = () => [
      {
        key: "1",
        label: header,
        children: (
          <Teeths
            teethChartDraftRef={teethChartDraftRef}
            chartValue={chartValue}
            disabled={disabled}
            teethStructure={teethStructure}
            toothStatuses={toothStatuses}
            setTeethStructure={setTeethStructure}
            setToothStatuses={setToothStatuses}
          />
        ),
      },
    ];
    return (
      <div className="teethChart-container">
        <Collapse
          defaultActiveKey={["1"]}
          collapsible="icon"
          bordered={false}
          expandIcon={({ isActive }) => (
            <div
              style={{
                transform: !isActive ? "rotate(270deg)" : "rotate(360deg)",
                transition: "all 0.3s",
              }}
            >
              <DownArrow style={{ fill: primaryColor }} />
            </div>
          )}
          items={getItems()}
        />

        <TeethInfo
          teethChartDraftRef={teethChartDraftRef}
          disabled={disabled}
          chartValue={chartValue}
          toothStatuses={toothStatuses}
          setToothStatuses={setToothStatuses}
          teethStructure={teethStructure}
          setTeethStructure={setTeethStructure}
        />
      </div>
    );
  }
);

export default TeethChart;
