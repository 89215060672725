import dayjs from "dayjs";
 import utc from "dayjs/plugin/utc";
 
 dayjs.extend(utc);
 
 export const displayDate = (date, format = "DD-MMM-YYYY") => {
   if (!date) {
     return "";
   }
   return dayjs.utc(date).format(format);
 };