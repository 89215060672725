import * as Sentry from "@sentry/react";
import hashHistory from "./history";
import { matchPath } from "react-router-dom";


// Array of Route Config Objects
// Make sure the order of the routes is correct. The longest url under the same parent should be placed first and in decreasing order.
const routes = [
  { path: "/clinic/patient/:patientId/:patientJourney?" },
  { "path": "/clinic/patient/:id/edit" },
  { "path": "/clinic/patient/:id" },
  { path: "/clinic/scheduling/:status" },
  { "path": "/clinic/patient/create" },
  { "path": "/clinic/patient/search" },
  { "path": "/clinic/patient/merge" },
  { "path": "/clinic/patient/import" },
  { "path": "/clinic/patient/export" },
  { "path": "/clinic/patient/upload" },
  { "path": "/clinic/patient/download" },
  { "path": "/clinic/patient/bulk-update" },
  { path: "/stl-viewer/:fileName" },
  { "path": "/clinic/patient" },
  { path: "/stripe/success" },
  { path: "/stripe/refresh" },
  { path: "/clinic/notifications" },
  { path: "/clinic/resources" },
  { path: "/clinic/profile" },
  { path: "/clinic/lab-partners" },
  { path: "/clinic/integrations" },
  { path: "/clinic/invoices" },
  { path: "/clinic/patients" },
  { path: "/clinic/archivePatients" },
  { path: "/clinic/doctorApp-scan" },
  { path: "/view-treatment-guide" },
  { path: "/clinic/whiteLabel" },
  { path: "/clinic/settings/*" },
  { path: "/clinic/settings" },
  { path: "/welcome-page" },
  { path: "/videoChatRoom" },
  { path: "/forgot-password" },
  { path: "/changePassword" },
  { path: "/onboarding" },
  { path: "/signup-confirmation" },
  { path: "/confirm-user" },
  { path: "/calendar" },
  { path: "/registration" },
  { path: "/login" },
  { path: "/404" },
  { path: "/" },

];

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.browserProfilingIntegration(),
    Sentry.reactRouterV5BrowserTracingIntegration({ history: hashHistory, matchPath, routes }),
    Sentry.replayIntegration(),
     // Sentry.breadcrumbsIntegration({
    //   console: false,
    //   dom: true,
    //   fetch: true,
    //   history: true,
    //   xhr: true,
    // }),
  ],
  // Tracing
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  // tracePropagationTargets: ["localhost", 'https://dev-api.smilegeniusapp.com/api/v1', /^https:\/\/dev-(api|patient|clinic)\.smilegeniusapp\.com\/?.*$/ ],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  profilesSampleRate: 1.0
});