import React, { useState, useEffect } from "react";
import { Button, Row, Col, Typography, Input, Radio, Form } from "antd";
import { useSelector } from "react-redux";

import "assets/styles/components/resources.scss";
import ResourcesList from "components/private/Resources/ResourcesList/ResourcesList";
import { getClinicLabPartner, getClinicResources } from "api/clinic";
import { getLabResourcesList, searchLabResources } from "api/lab";
import dayjs from "dayjs";
import ResourceModal from "components/private/Resources/Components/ResourceModal/ResourceModal";
import AppButton from "components/shared/AppButton/AppButton";
import {
  MY_RESOURCES,
  resourcesTabOptions,
  SHARED_RESOURCES,
} from "utilities/constants";
import ResourceViewEditModal from "components/private/Resources/ResourceViewEditModal/ResourceViewEditModal";
import { displayDate } from "components/shared/Date/Date";

const { Title } = Typography;

const Resources = () => {
  const clinic = useSelector(state => state.clinic);

  const [labId, setLabId] = useState();
  const [tableLoading, setTableLoading] = useState(false);
  const [resources, setResources] = useState();
  const [value, setValue] = useState(SHARED_RESOURCES);
  const [clinicResources, setClinicResources] = useState([]);
  const [finalClinicResources, setFinalClinicResources] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [searchLoading, setSearchLoading] = useState(false);
  const [showAddResourceModal, setShowAddResourceModal] = useState(false);
  const [addResourceForm] = Form.useForm();
  const [showDetailModal, setShowDetailModal] = useState(false);
  const [resourceDetail, setResourceDetail] = useState(null);

  useEffect(() => {
    if (clinic.clinicId) {
      getClinicLabPartnerApi(clinic.clinicId);
    }
  }, [clinic]);

  useEffect(() => {
    if (clinic.clinicId && labId) {
      if (searchValue) {
        if (value === MY_RESOURCES) {
          searchClinicResource(searchValue);
        } else {
          onSearchResourcesApi();
        }
      } else {
        if (value === MY_RESOURCES) {
          getResources(clinic.clinicId);
        } else {
          getClinicSharedResourcesApi(clinic.clinicId, labId);
        }
      }
    }
  }, [searchValue, clinic.clinicId, labId, value]);

  const searchClinicResource = searchValue => {
    const searchClinicResource = finalClinicResources.filter(obj => {
      if (
        obj?.name?.resourceName &&
        obj.name.resourceName.includes(searchValue)
      ) {
        return true;
      }
      return false;
    });
    setClinicResources(searchClinicResource);
  };

  const onSearchResourcesApi = async () => {
    try {
      setSearchLoading(true);
      const searchResponse = await searchLabResources(clinic.clinicId, labId, {
        search: searchValue,
      });
      const finalRes = mapResourceData(searchResponse?.data?.data);
      setResources(finalRes);
    } catch (error) {
      console.log("error", error);
    } finally {
      setSearchLoading(false);
    }
  };

  const mapResourceData = resourceList => {
    return resourceList.map(resource => {
      return {
        name: { resourceName: resource.name, resourceId: resource.id },
        shareList: { clinic: resource.labDetails?.lab_name },
        category: resource.category,
        uploaded: resource.createdAt
          ? displayDate(resource.createdAt)
          : "",
        files: resource.files.length
          ? resource.files.filter(file => file.mimeType !== "link")
          : [],
        id: resource.id,
        description: resource.description,
        videoLinks: resource.files.length
          ? resource.files.filter(file => file.mimeType === "link")
          : [],
      };
    });
  };

  const getResources = async clinicId => {
    try {
      const servicesRes = await getClinicResources({
        clinicId: clinicId,
      });
      const finalRes = servicesRes?.body?.clinic_resources.map(resource => {
        return {
          name: {
            resourceName: resource.resource_title,
            resourceId: resource.resource_id,
          },
          shareList: { clinic: "Me" },
          category: resource.file_type,
          uploaded: resource.date_created
            ? displayDate(resource.date_created)
            : "",
          files:
            resource.file_type === "file"
              ? [
                  {
                    url: resource.resource_url,
                    name: resource.file_name,
                    mimeType: "",
                  },
                ]
              : [],
          id: { id: resource.resource_id, name: resource.file_name },
          description: resource.file_name,
          videoLinks:
            resource.file_type === "url"
              ? [{ url: resource.resource_url, name: resource.file_name }]
              : [],
        };
      });
      setFinalClinicResources(finalRes);
      setClinicResources(finalRes);
    } catch (error) {
      console.log("error", error);
    }
  };

  const getClinicLabPartnerApi = async clinicId => {
    try {
      // setTableLoading(true);
      const labPartnerResponse = await getClinicLabPartner(clinicId);
      if (labPartnerResponse?.data?.body?.partnerLabDetails?.length) {
        setLabId(labPartnerResponse.data.body.partnerLabDetails[0].lab_id);
      }
    } catch (error) {
      // setTableLoading(false);
      console.log("error", error);
    }
  };

  const getClinicSharedResourcesApi = async (clinicId, labId) => {
    try {
      setTableLoading(true);
      const { data } = await getLabResourcesList(clinicId, labId);

      if (data) {
        const finalRes = mapResourceData(data?.data);
        setResources(finalRes);
      }
    } catch (error) {
      console.log("error", error);
    } finally {
      setTableLoading(false);
    }
  };

  const onChange4 = ({ target: { value } }) => {
    setValue(value);
    if (value !== MY_RESOURCES) {
      getClinicSharedResourcesApi(clinic.clinicId, labId);
    }
  };

  const onSearchValue = value => {
    setSearchValue(value);
  };

  const handleAddResource = () => {};

  const onShowResource = id => {};

  const handleRowClick = record => {
    let records = [...resources];
    if (value === MY_RESOURCES) {
      records = clinicResources;
    }
    const filterResource = records.find(
      resource => resource.name.resourceId === record?.name?.resourceId
    );
    setResourceDetail(filterResource);
    setShowDetailModal(true);
  };

  return (
    <div className="resources-container">
      <Row style={{ justifyContent: "space-between" }} gutter={[8,16]}>
        <Col style={{ textAlign: "start" }} lg={13} md={24} sm={24}>
          <div className="resource-title">
            <Title level={3}>Resources</Title>
            <Radio.Group
              options={resourcesTabOptions}
              onChange={onChange4}
              value={value}
              optionType="button"
              buttonStyle="solid"
            />
          </div>
        </Col>
        <Col  lg={9} md={24} sm={24}>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
              gap: "1rem",
            }}
          >
            <Input.Search
              size="large"
              // onSearch={(e) => onSearch(e.trim())}
              allowClear={true}
              enterButton={true}
              onChange={e => onSearchValue(e.target.value)}
              onSearch={onSearchValue}
              loading={searchLoading}
              // enterButton={true}
              // prefix={<SearchOutlined />}
              addonAfter={null}
              // className="searchBox"
              // style={{
              //   width: 331,
              // }}
              placeholder="Search Resource"
            />
            <AppButton
              type="primary"
              shape="round"
              onClick={() => setShowAddResourceModal(true)}
            >
              Add Patient Guide
            </AppButton>
          </div>
        </Col>
      </Row>

      {value === MY_RESOURCES ? (
        <ResourcesList
          tableLoading={tableLoading}
          setTableLoading={setTableLoading}
          resources={clinicResources}
          setResources={setClinicResources}
          value={value}
          searchValue={searchValue}
          setSearchLoading={setSearchLoading}
          clinic={clinic}
          setClinicResources={setClinicResources}
          onShowResource={onShowResource}
          handleRowClick={handleRowClick}
        />
      ) : (
        <ResourcesList
          tableLoading={tableLoading}
          setTableLoading={setTableLoading}
          resources={resources}
          setResources={setResources}
          value={value}
          searchValue={searchValue}
          setSearchLoading={setSearchLoading}
          clinic={clinic}
          onShowResource={onShowResource}
          handleRowClick={handleRowClick}
        />
      )}

      {showAddResourceModal && (
        <ResourceModal
          addResourceForm={addResourceForm}
          showAddResourceModal={showAddResourceModal}
          setShowAddResourceModal={setShowAddResourceModal}
          handleAddResource={handleAddResource}
          clinic={clinic}
          clinicResources={clinicResources}
          setClinicResources={setClinicResources}
        />
      )}

      {showDetailModal && (
        <ResourceViewEditModal
          showDetailModal={showDetailModal}
          setShowDetailModal={setShowDetailModal}
          resource={resourceDetail}
        />
      )}
    </div>
  );
};

export default Resources;
