import { Form, Checkbox, Button } from "antd";
import { primaryColor, companyType } from "colors-config";
import AppButton from "components/shared/AppButton/AppButton";
import { getOrderBtnBackground } from "utilities/settings";

const Footer = ({
  dataAcquisitionURL,
  termsAndConditionsURL,
  setEditMode,
  editMode,
  PRESCRIPTION_FORM_STATUS,
  disabled,
  onFormSubmit,
}) => {
  const { INITIAL, SUBMITTED, PARTIAL } = PRESCRIPTION_FORM_STATUS;
  return (
    <div className="formFooter">
      <div className="formRowOne">
        <Form.Item
          className="terms"
          valuePropName="checked"
          rules={[
            {
              validator: (_, value) =>
                value
                  ? Promise.resolve()
                  : Promise.reject(
                      new Error("Should accept terms and conditions")
                    ),
            },
          ]}
          name="terms_conditions"
        >
          <Checkbox disabled={disabled}>
            <span>
              Lab&nbsp;
              <a
                href={termsAndConditionsURL}
                style={{ color: primaryColor }}
                target="_blank"
                rel="noreferrer"
              >
                {companyType === 8
                  ? "Terms and Conditions and data acquisition"
                  : "Terms and Conditions"}
              </a>
            </span>
          </Checkbox>
        </Form.Item>

        {companyType !== 8 && (
          <Form.Item
            rules={[
              {
                validator: (_, value) =>
                  value
                    ? Promise.resolve()
                    : Promise.reject(new Error("Should accept agreement")),
              },
            ]}
            name="data_consent"
            valuePropName="checked"
          >
            <Checkbox disabled={disabled}>
              <span>
                Lab declaration of&nbsp;
                <a
                  href={dataAcquisitionURL}
                  style={{ color: primaryColor }}
                  target="_blank"
                  rel="noreferrer"
                >
                  Consent Data Acquisition and Processing
                </a>
              </span>
            </Checkbox>
          </Form.Item>
        )}
      </div>
      <Form.Item>
        <div className="buttons-row">
          <AppButton
            shape="round"
            // className="cancel-btn"
            disabled={disabled}
            onClick={() => {
              if (editMode !== INITIAL) {
                setEditMode(SUBMITTED);
              }
            }}
          >
            Cancel
          </AppButton>
          <AppButton
            type="primary"
            onClick={onFormSubmit}
            disabled={disabled}
            shape="round"
            style={{
              marginLeft: "12px",
              background: disabled
                ? "rgba(0, 0, 0, 0.04)"
                : getOrderBtnBackground(),
            }}
          >
            Submit
          </AppButton>
        </div>
      </Form.Item>
    </div>
  );
};

export default Footer;
