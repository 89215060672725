import * as Sentry from "@sentry/react";


export const setSentryUserContext = ({ user }) => {
    const { username, attributes, pool } = user;
    
    // Set user information
    Sentry.setUser({
        id: pool?.userPoolId || username, // Replace with actual user ID
        username, // Replace with actual username
        ...attributes,
        clientId: pool?.clientId,
        userPoolId: pool?.userPoolId
    });
}


// To clear user context
export const clearSentryUserContext = () => {
    Sentry.setUser(null);
}