import { useState, useEffect, forwardRef, useImperativeHandle } from "react";
import "../../../assets/styles/components/progressImageComponent.scss";
import { Button, Row, Col, Modal, message, Select, Image, Card, Form, Popconfirm } from "antd";
import { primaryColor, progressImageBorderColor } from "colors-config";
import dayjs from "dayjs";
import axios from "axios";

import DeleteConfirmationModal from "../../shared/modals/deleteConfirmationModal";

import UploadImage from "./uploadImageComponent";
import { DeleteOutlined, DownloadOutlined } from "@ant-design/icons";
import { DownloadIcon } from "components/shared/Icon/index";
import { downloadS3File } from "api/patient";
import { formatImageView, sortPhotosByOrder } from "utilities/patientDetail";
import { displayDate } from "components/shared/Date/Date";
const { Option } = Select;

function ProgressImageComponent(
  {
    imageStageTypes,
    fullData,
    patientId,
    listOfAligners,
    caseId,
    selectedCaseId,
    getAlignerUrls,
    setAlignerObject,
    alignerObject,
    getTreatmentId,
    treatmentId,
    deleteProgressImage,
    setIsProgressImagesSpinning,
    clinicId,
    companyType,
    impressionCheck,
    currentAlignerStage,
    myDivRef,
  },
  ref
) {
  const [allProgressImages, setAllProgressImages] = useState([]);
  const [images, setImages] = useState([]);
  const [onlyUpload, setOnlyUpload] = useState([]);
  const [alignersList, setAlignersList] = useState();
  const [stageId, setStageId] = useState();
  const [Count, setCount] = useState();

  const [imageToBeDeleted, setImageToBeDeleted] = useState(null);

  const [impressionList, setImpressionList] = useState([]);
  const [showImageDeleteConfirmationModel, setShowImageDeleteConfirmationModel] = useState(false);

  const [confirmAlignerData, setConfirmAlignerData] = useState({
    patient_id: patientId,
    patient_upload_stage: currentAlignerStage == 500 ? "final" : "preliminary",
    aligner_id: "100",
    lab_treatment_id: "",
  });

  const [isModalRejectVisible, setIsModalRejectVisible] = useState(false);
  const [rejectPopLoading, setRejectPopLoading] = useState(false);
  const [patientDetails, setPatientDetails] = useState();
  const [form] = Form.useForm();
  const [rejectReasonList, setRejectReasonList] = useState();

  const [open, setOpen] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [alignerName, setAlignerName] = useState({ hasU: true, hasL: true });
  useImperativeHandle(ref, () => ({
    stageId,
  }));

  useEffect(() => {
    let imagesData = [];
    // console.log("imageStageTypes",imageStageTypes);
    // console.log("fullData",fullData);
    
    const imageStageTypeKeys = Object.keys(imageStageTypes);
    setOnlyUpload([]);
    renderUploadImages(fullData);
    renderAlignersList(listOfAligners);

    if (imageStageTypeKeys.length) {
      // setDefaultImageType(imageStageTypes[imageStageTypeKeys[0]]);

      setImages([]);
      imagesData = imageStageTypes[imageStageTypeKeys[0]].sort((a, b) =>
        a.rowId > b.rowId ? 1 : -1
      );
      createImages(imagesData);
    } else {
      createImages([]);
    }
    // console.log("imagesData",imagesData);
    
    const combined = [...fullData, ...imagesData];
    // console.log("combined",combined);
    
    const finalData = sortPhotosByOrder(combined);
     console.log("finalDatafinalData11111", finalData)
    setAllProgressImages(finalData);
  }, [imageStageTypes]);

  function checkUandL(str) {
    let hasU = false;
    let hasL = false;

    for (let char of str) {
      if (char === "U") {
        hasU = true;
      }
      if (char === "L") {
        hasL = true;
      }
    }

    return {
      hasU,
      hasL,
    };
  }

  useEffect(() => {
    if (stageId === -1) {
      getImpressionImages();
    }

    if (stageId && stageId !== 100) {
      const typeOfStage = listOfAligners.patient_image_upload_stages.find(
        (stage) => stage.patient_image_uploaded_stage_id === stageId
      );

      const imageDisplayOption = checkUandL(typeOfStage.patient_image_uploaded_stage_name);
      setAlignerName(imageDisplayOption);
    }
  }, [stageId]);

  useEffect(() => {
    if (currentAlignerStage == 500) {
      setStageId(500);
    }
    if (currentAlignerStage && currentAlignerStage !== -1) {
      _getTreatmentID();
    }
  }, [currentAlignerStage]);

  useEffect(() => {
    setCount(listOfAligners && listOfAligners.current_stage);
  }, [listOfAligners]);

  useEffect(() => {
    getTreatmentId();
    setImages([]);
    // getId();
  }, [Count, treatmentId]);

  const getImpressionImages = async () => {
    setIsProgressImagesSpinning(true);
    try {
      await getPatientDetails();
      const res = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/patient/resource/getImpressionsImages?patient_id=${patientId}`
      );

      if (res?.data?.body?.success?.length) {
        setImpressionList(res.data.body.success);
      }
    } catch (error) {
      console.error(error);
      message.error("Failed to get images. Please try again!");
    } finally {
      setIsProgressImagesSpinning(false);
    }
  };

  const getPatientDetails = async () => {
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/patient/getDetails?patientId=${patientId}`
      );
      if (res?.data?.body?.length) {
        setPatientDetails(res.data.body[0]);
      }
    } catch (error) {
      console.error(error);
      message.error("Failed to get patient. Please try again!");
    }
  };

  const setImpressionDecision = async (status, reject = "") => {
    try {
      const approvedText = `Congratulations! Your Impressions have been approved, well done. 🎉 \n\n
Your customized 3D treatment plan will be ready in 3-7 business days after your impressions have been received, crafted by your dedicated dental team. \n\n
Please reach out if you have any questions.
            `;
      const res = await axios.post(`${process.env.REACT_APP_BASE_URL}/comment/impressionDecision`, {
        patient_id: patientId,
        status,
        comment_description:
          status == 1
            ? approvedText
            : "Great effort! Unfortunately your impressions are unsuitable.\n\nReason : " + reject,
        module: "clinic",
        receiver_id: patientId,
        sender_id: clinicId,
      });
      message.success("Status has been updated");
      getPatientDetails();
      return;
    } catch (error) {
      console.error(error);
      message.error("Failed to set status. Please try again!");
    }
  };

  const getId = () => {
    setImages([]);
    listOfAligners && listOfAligners.current_stage !== 100 && getTreatmentId();
    listOfAligners &&
      listOfAligners.current_stage !== 100 &&
      setAlignerObject({
        ...alignerObject,
        patientStage: listOfAligners.current_stage
          ? listOfAligners.current_stage - 1 === 0 && 99
            ? "final"
            : "aligner"
          : "final",
        alignerId:
          listOfAligners.current_stage - 1 !== 0 && 99 ? listOfAligners.current_stage - 1 : 100,
        labTreatmentId: listOfAligners.current_stage !== 100 ? treatmentId : "",
      });
  };
  const download = async (file) => {
    if (file?.imageDeleteRefUrl) {
      const fileName = file.imageDeleteRefUrl?.split("/")[1];
      if (fileName) {
        window.open(downloadS3File(fileName));
      }
    }
  };

  const createImages = (images) => {
    setImages([]);
    setImages(
      images.map((image) => {
        var withOutAccessKeyImg = image.imageUrl.indexOf("?");
        var processedImg = image.imageUrl.slice(0, withOutAccessKeyImg);
        return (
          <>
            {image?.imageViewType !== null && (
              <div className="image-Card" style={{ border: progressImageBorderColor }}>
                {/* <div
                className="image-Card"
                style={{ borderColor: primaryColor }}
              > */}
                <Row>
                  <p>{image?.imageViewType?.replace(/([A-Z])/g, " $1").trim()}</p>
                  <DeleteOutlined
                    style={{
                      color: "red",
                      marginLeft: "0.5rem",
                      marginTop: "0.2rem",
                    }}
                    onClick={() => {
                      setImageToBeDeleted(image);
                      setShowImageDeleteConfirmationModel(true);
                    }}
                  />
                  <DownloadIcon
                    marginLeft={"0.5rem"}
                    onClick={() => {
                      download(image);
                    }}
                  />
                </Row>
                <Image className="image-dim" src={processedImg} />
              </div>
            )}
          </>
        );
      })
    );
  };

  const updateAlignerUrl = (data) => {
    setConfirmAlignerData(data);
  };

  const _getTreatmentID = () => {
    getTreatmentId();
    let patientStage = "preliminary";
    if (stageId) {
      if (stageId == 500) {
        patientStage = "final";
      } else if (stageId == 100) {
        patientStage = "preliminary";
      } else {
        patientStage = "aligner";
      }
    } else {
      if (currentAlignerStage == 500) {
        patientStage = "final";
      }
    }

    // treatmentId &&
    //   setAlignerObject({
    //     ...alignerObject,
    //     patientStage: stageId
    //       ? stageId !== 100
    //         ? stageId == 500
    //           ? "final"
    //           : "aligner"
    //         : "preliminary"
    //       : "preliminary",
    //     alignerId: stageId,
    //     labTreatmentId: stageId !== 100 ? treatmentId : "",
    //   });

    handleAlignerData(currentAlignerStage);

    // const uploadStage =
    //   currentAlignerStage === 100
    //     ? "preliminary"
    //     : currentAlignerStage == 500
    //     ? "final"
    //     : "aligner";

    // setConfirmAlignerData({
    //   patient_id: patientId,
    //   patient_upload_stage: uploadStage,
    //   aligner_id: currentAlignerStage
    //     ? currentAlignerStage
    //     : listOfAligners?.current_stage,
    //   lab_treatment_id: currentAlignerStage !== 100 ? treatmentId : "",
    // });
  };

  const renderUploadImages = (types) => {
    setOnlyUpload([]);

    setOnlyUpload(
      types &&
        types.map((item) => {
          return (
            <div className="image-Card" style={{ border: progressImageBorderColor }}>
              <p>{item.patient_image_view.replace(/([A-Z])/g, " $1").trim()}</p>
              <UploadImage
                patientDet={patientId}
                viewType={item.patient_image_view}
                caseId={caseId}
                selectedCaseId={selectedCaseId}
                getAlignerUrls={getAlignerUrls}
                confirmAlignerData={confirmAlignerData}
                setConfirmAlignerData={updateAlignerUrl}
                treatmentId={treatmentId}
                stageId={stageId}
              />
            </div>
          );
        })
    );
  };

  const renderAlignersList = (list) => {
    const defaultAligner =
      list && listOfAligners && listOfAligners.current_stage ? listOfAligners.current_stage : 100;

    const lastAlignerStage = list && listOfAligners && listOfAligners.last_aligner_image_uploaded;

    if (companyType && companyType == 3 && list?.patient_image_upload_stages?.length) {
      const checkImpressionOption = list.patient_image_upload_stages.filter(
        (obj) => obj.patient_image_uploaded_stage_id == -1
      );
      if (!checkImpressionOption.length) {
        list.patient_image_upload_stages.unshift({
          patient_image_uploaded_stage_id: -1,
          patient_image_uploaded_stage_name: "Impressions",
        });
      }
    }

    let defaultValue =
      lastAlignerStage === true
        ? defaultAligner === 100
          ? 100
          : defaultAligner
        : defaultAligner === 100
        ? 100
        : defaultAligner > 1
        ? defaultAligner - 1
        : 100;
    if (impressionCheck) {
      defaultValue = -1;
      setStageId(-1);
    }
    if (defaultValue == 499) {
      defaultValue = 500;
    }

    list &&
      setAlignersList(
        <Select
          size="large"
          style={{ width: 175 }}
          onChange={(info) => {
            setImages([]);
            onSelectImageStageTypeChange(info);
          }}
          defaultValue={defaultValue}
          value={stageId}
        >
          {list.patient_image_upload_stages &&
            list.patient_image_upload_stages.map((item) => {
              return (
                <Option
                  key={item.patient_image_uploaded_stage_id}
                  value={item.patient_image_uploaded_stage_id}
                >
                  {item.patient_image_uploaded_stage_name}
                </Option>
              );
            })}
        </Select>
      );
  };

  const handleAlignerData = (currentStageId) => {
    const uploadStage =
      currentStageId === 100 ? "preliminary" : currentStageId == 500 ? "final" : "aligner";

    const labTreatmentId = currentStageId !== 100 ? treatmentId : "";

    setConfirmAlignerData({
      patient_id: patientId,
      patient_upload_stage: uploadStage,
      aligner_id: currentStageId ? currentStageId : listOfAligners?.current_stage,
      lab_treatment_id: labTreatmentId,
    });

    treatmentId && getAlignerUrls(currentStageId);
  };

  const onSelectImageStageTypeChange = (imageTypeId) => {
    handleAlignerData(imageTypeId);
    setStageId(imageTypeId);
  };

  const deleteImage = () => {
    deleteProgressImage(imageToBeDeleted.imageDeleteRefUrl);
    setImageToBeDeleted(null);
  };

  const onOkRejectApi = async (values) => {
    setRejectPopLoading(true);
    try {
      await setImpressionDecision(0, values.reject);
      form.resetFields();
      setIsModalRejectVisible(false);
    } catch (err) {
    } finally {
      setRejectPopLoading(false);
    }
  };

  const handleRejectCancel = () => {
    setIsModalRejectVisible(false);
  };

  const getReasons = async () => {
    try {
      const res = await axios.get(`${process.env.REACT_APP_BASE_URL}/getRemakeReasons`);
      if (res?.data?.body?.data?.length) {
        setRejectReasonList(res.data.body.data);
      }
      setIsModalRejectVisible(true);
    } catch (error) {
      console.error(error);
      message.error("Failed to get reasons. Please try again!");
    }
  };

  const handleImpressionApprove = async () => {
    try {
      setConfirmLoading(true);
      await setImpressionDecision(1);
    } catch (error) {
      console.log("error", error);
    } finally {
      setTimeout(() => {
        setConfirmLoading(false);
        setOpen(false);
      }, 3000);
    }
  };

  const showPopconfirm = () => {
    setOpen(true);
  };

  const handleCancel = () => {
    setOpen(false);
  };

  // const onAlignerChange = (e) => {
  //   console.log(e)
  // };

  // const alignerSelectMenu = () => {
  //   return (
  //     <Select onChange={onAlignerChange}>
  //       {list.patient_image_upload_stages &&
  //         list.patient_image_upload_stages.map((item) => {
  //           return (
  //             <Option
  //               key={item.patient_image_uploaded_stage_id}
  //               value={item.patient_image_uploaded_stage_id}
  //             >
  //               {item.patient_image_uploaded_stage_name}
  //             </Option>
  //           );
  //         })}
  //     </Select>
  //   );
  // };
  return (
    <div style={{ height: "100%" }}>
      {showImageDeleteConfirmationModel && imageToBeDeleted && (
        <DeleteConfirmationModal
          title="Delete Image"
          content={
            imageToBeDeleted.imageStageName
              ? `(${imageToBeDeleted.imageStageName} - ${imageToBeDeleted.imageViewType})`
              : `(${imageToBeDeleted.imageViewType})`
          }
          showModal={showImageDeleteConfirmationModel}
          onConfirmation={deleteImage}
          setShow={setShowImageDeleteConfirmationModel}
          mode={"deleteImage"}
        ></DeleteConfirmationModal>
      )}
      <Card
        title="Progress Images"
        extra={alignersList}
        className={`progress-image-card ${companyType == 3 ? "impression-image-card" : ""} ${
          !impressionList.length && stageId == -1 ? "no-impression-list" : ""
        }`}
      >
        <div
          className="preview-content"
          style={{
            maxHeight: `${myDivRef?.current?.clientHeight - 100}px`,
            overflowY: "auto",
          }}
        >
          {stageId != -1 && (
            <div className="image-upload-container">
              {allProgressImages.map((item, i) => {
                const withOutAccessKeyImg = item?.imageUrl?.indexOf("?");
                const processedImg = item?.imageUrl?.slice(0, withOutAccessKeyImg);

                if (stageId !== 100) {
                  if (
                    alignerName.hasL &&
                    !alignerName.hasU &&
                    item.patient_image_view === "TopTeeth"
                  ) {
                    return null;
                  }

                  if (
                    !alignerName.hasL &&
                    alignerName.hasU &&
                    item.patient_image_view === "BottomTeeth"
                  ) {
                    return null;
                  }
                }
                return (
                  <div key={i}>
                    {item.imageUrl ? (
                      <>
                        {item?.imageViewType !== null && (
                          <div className="image-Card" style={{ borderColor: primaryColor }}>
                            <Row>
                              <p>{formatImageView(item.imageViewType)}</p>
                              <DeleteOutlined
                                style={{
                                  color: "red",
                                  marginLeft: "0.5rem",
                                  marginTop: "0.2rem",
                                }}
                                onClick={() => {
                                  setImageToBeDeleted(item);
                                  setShowImageDeleteConfirmationModel(true);
                                }}
                              />
                              <DownloadIcon
                                marginLeft={"0.5rem"}
                                onClick={() => {
                                  download(item);
                                }}
                              />
                            </Row>
                            <Image className="image-dim" src={processedImg} />
                          </div>
                        )}
                      </>
                    ) : (
                      <div className="image-Card" style={{ borderColor: primaryColor }}>
                        <p>{formatImageView(item.patient_image_view)}</p>
                        <UploadImage
                          patientDet={patientId}
                          viewType={item.patient_image_view}
                          caseId={caseId}
                          selectedCaseId={selectedCaseId}
                          getAlignerUrls={getAlignerUrls}
                          confirmAlignerData={confirmAlignerData}
                          setConfirmAlignerData={updateAlignerUrl}
                          treatmentId={treatmentId}
                          stageId={stageId}
                        />
                      </div>
                    )}
                  </div>
                );
              })}
            </div>
          )}
          <div className="impressions-image-card">
            {stageId == -1 && (
              <>
                <div className="image-upload-container">
                  <Image.PreviewGroup>
                    {impressionList?.length ? (
                      impressionList.map((impression, index) => {
                        return (
                          <div key={index} className="image-Card" style={{}}>
                            <Row>
                              <p>
                                Impression {index + 1}{" "}
                                {displayDate(impression.upload_date)}
                              </p>
                            </Row>
                            <Image
                              height={160}
                              className="image-impressions"
                              src={impression.url}
                            />
                          </div>
                        );
                      })
                    ) : (
                      <Row className="empty-plan-component" style={{ margin: "8px !important" }}>
                        No impressions have been taken yet.
                      </Row>
                    )}
                  </Image.PreviewGroup>
                </div>
                <div></div>
                {impressionList?.length ? (
                  <div>
                    <Row justify="end">
                      <Col span={12} className="impression-approve-container">
                        {patientDetails?.impression_decision != 1 ? (
                          <>
                            <Button
                              style={{
                                borderRadius: "5rem",
                                fontFamily: "Mulish",
                                marginRight: "10px",
                              }}
                              onClick={() => getReasons()}
                            >
                              Reject
                            </Button>

                            <Popconfirm
                              title="Confirm impressions are suitable."
                              open={open}
                              onConfirm={handleImpressionApprove}
                              okText="Confirm"
                              cancelText="Cancel"
                              okButtonProps={{
                                loading: confirmLoading,
                              }}
                              onCancel={handleCancel}
                            >
                              <Button
                                type="primary"
                                onClick={showPopconfirm}
                                // onClick={() => {  }} setImpressionDecision(1)
                                style={{
                                  borderRadius: "5rem",
                                  fontFamily: "Mulish",
                                  marginRight: "10px",
                                }}
                              >
                                Approve
                              </Button>
                            </Popconfirm>
                          </>
                        ) : (
                          <p
                            style={{
                              background: "#E4F5EB",
                              borderRadius: "34px",
                              color: "green",
                              padding: "0.4rem",
                              width: "7rem",
                              textAlign: "center",
                              margin: "0",
                            }}
                          >
                            Approved
                          </p>
                        )}
                      </Col>
                    </Row>
                  </div>
                ) : (
                  ""
                )}
              </>
            )}
          </div>
        </div>
      </Card>

      <Modal
        title="Reject Impressions"
        okText="Confirm"
        cancelText="Cancel"
        visible={isModalRejectVisible}
        confirmLoading={rejectPopLoading}
        onOk={form.submit}
        onCancel={handleRejectCancel}
        okButtonProps={{
          style: { width: "7rem", borderRadius: "30px" },
        }}
        cancelButtonProps={{ style: { width: "7rem", borderRadius: "30px" } }}
      >
        <p>Please select reason.</p>
        <Form form={form} onFinish={onOkRejectApi}>
          <Form.Item
            name="reject"
            label=""
            rules={[{ required: true, message: "Reject Reason is required" }]}
          >
            <Select size="large" allowClear>
              {rejectReasonList &&
                rejectReasonList.map((reason, index) => {
                  return (
                    <Option key={index} value={reason.order_remake_reason_name}>
                      {reason.order_remake_reason_name}
                    </Option>
                  );
                })}
            </Select>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
}

export default forwardRef(ProgressImageComponent);
